interface Data extends __WebpackModuleApi.Module {
  default: string;
}

const importAll = (
  webpackContext: __WebpackModuleApi.RequireContext
): { slug: string; body: Data }[] => {
  return webpackContext.keys().map((fileUrl) => {
    const body: Data = webpackContext(fileUrl);

    const slug: string = fileUrl;

    return {
      slug,
      body,
    };
  });
};

const ClientImages = importAll(
  require.context('./', false, /\.(png|jpe?g|svg)$/)
);

export default ClientImages;
