import React, { ReactElement } from 'react';
import './SmsPin.scss';
import SubPageHero from '../../../components/SubPageHero/SubPageHero';
import HeroImage from '../../../assets/images/hero/landscape.jpg';
import SecondaryNav from '../../../components/SecondaryNav/SecondaryNav';
import { useTranslation } from 'react-i18next';
import { upperCase } from 'converting-case';

const SmsPin: React.FC = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <div className="SmsPin">
      <SecondaryNav />
      <SubPageHero image={HeroImage}>
        <h2>{upperCase(t('SmsPin.header'))}</h2>
        <p>{t('SmsPin.description part 1')}</p>
        <a
          className="button is-outlined"
          href="https://api.messenger.ee"
          target="_blank"
          rel="noreferrer"
        >
          {upperCase(t('documentation'))}
        </a>
      </SubPageHero>
      <div id="platform-comparison" className="container">
        <div className="columns">
          <div className="column card">
            <div className="card-header">
              <p className="card-header-title">
                {upperCase(t('smart account'))}
              </p>
            </div>
            <div className="card-content">
              <div className="content">{t('SmsPin.platform 1')}</div>
              <a
                className="button is-primary"
                href="https://smart.messenger.ee/register"
                target="_blank"
                rel="noreferrer"
              >
                {upperCase(t('get started'))}
              </a>
            </div>
          </div>
          <div className="column card">
            <div className="card-header">
              <p className="card-header-title">
                {upperCase(t('premium account'))}
              </p>
            </div>
            <div className="card-content">
              <div className="content">{t('SmsPin.platform 2')}</div>

              <a
                className="button is-primary"
                href="mailto:support@messenger.ee"
              >
                {upperCase(t('contact us'))}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SmsPin;
