import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import './PrivacyPolicy.scss';

const Et: React.FC = (): ReactElement => {
  return (
    <div className="Et">
      <h3>Andmekaitsetingimused</h3>
      <p>
        Direct Messenger OÜ austab eraelu puutumatust ja on täielikult
        pühendunud isikuandmete kaitsmisele ja nende kasutamisele vastavalt
        seadusest tulenevatele nõuetele. Järgnevad andmekaitsetingimused
        kirjeldavad seda, kuidas meie isikuandmeid töötleme ja millised õigused
        on seoses sellega meie Klientidel ja Andmesubjektidel. Direct Messenger
        töötleb isikuandmeid ainult käesolevates andmekaitsetingimustes
        kirjeldatud eesmärkidel.
      </p>
      <p>
        Meie Teenuste kasutamise või SMS platvormile sisenemise ja kasutamisega
        kinnitate oma nõusolekut kõigi tingimustega, mis on toodud käesolevates
        andmekaitsetingimustes, kasutus­tingimustes ja muudes meie platvormile
        postitatud eeskirjades. Klient ja Direct Messenger järgivad kõiki
        kohaldatavaid seadusi, mis puudutavad isikuandmete töötlemist ja
        kaitset.
      </p>
      <h3>Andmete töötlemine</h3>
      <p>
        Teenuste osutamise käigus töötleme andmeid, et tuvastada teie isikut,
        võtta teiega ühendust ja saata teile arveid, kogume selliseid andmeid
        nagu nimi, perekonnanimi, ettevõtte nimi, e-posti aadress ja
        telefoninumber, makseandmed ja muu teave, mille te meile edastate.
      </p>
      <p>
        Selleks, et meie Teenuste puhul tugiteenuseid osutada, teid paremini
        teenindada ning oma Teenuseid täiustada ja rakendada, töötleme teie
        kontoga seotud andmeid. Näiteks telefoninumbrid, sõnumi sisu ja muud
        sellega seotud teavet, mida teie Kliendina olete otsustanud kasutada
        seoses teile osutatud Teenustega, mis on üksikasjalikumalt toodud välja
        teie vastaval kontol.
      </p>
      <p>
        Isikuandmete töötlemisel ühenduses meie Teenustega (nt edastades
        sõnumeid ettevõtte või teise isiku nimel), kinnitate, et olete omandanud
        kõik vajalikud nõusolekud või on teil olemas muu õiguslik alus
        isikuandmete töötlemiseks kaasa arvatud, kuid mitte ainult, Direct
        Messengeri ja kolmandate isikute andmed, keda Direct Messenger kasutab
        Teenuste osutamiseks.
      </p>
      <p>
        Teie e-posti aadressi või telefoninumbrit töödeldakse selleks, et saata
        teile teateid, mis on hädavajalikud meie Teenuste osutamiseks.
      </p>
      <p>
        Sujuva kliendisuhte tagamiseks töötleme me teavet, mida te esitate meie
        platvormil, e‑mailides või muude sidevahendite kaudu. Me kasutame seda
        teavet oma Teenuste osutamiseks ja täiustamiseks ning vastame teie
        küsimustele seoses pakutavate Teenustega.
      </p>
      <h3>Andmete turvalisus</h3>
      <p>
        Kooskõlas kohalduvate seadusega kasutame mõistlikke ja asjakohaseid
        korralduslikke, tehnilisi ja administratiivseid meetmeid, et kaitsta
        isikuandmete konfidentsiaalsust, terviklikkust ja kättesaadavust.
      </p>
      <p>
        Andmebaase hoitakse serverites, mis on kaitstud tulemüüride, salasõnade
        ja muude vajalike tehniliste meetmetega, et kaitsta meie servereid
        volituseta süsteemi pääsemise eest, lubades ainult usaldusväärsetel
        töötajatel meie süsteeme hallata. Nõutav on vajalike turva­meetmete
        kasutamine andmete juurdepääsu ja nende käsitlemise tarvis. Andmebaaside
        turvakoopiaid hoitakse lukustatud kohtades, millele pääsevad ligi ainult
        volitatud isikud.
      </p>
      <p>
        Direct Messenger kasutab HTTPS-ühendust, mis tähendab, et arvutiühendus
        meie süsteemiga on krüpteeritud. Internetilehitseja väli koos tabaluku
        märgiga viitab turvalisele ühendusele. Sertifikaadi autentsuse
        kontrollimiseks vajutage tabaluku märgil.
      </p>
      <p>
        Kahjuks ei saa ühegi andmeedastus- ega hoiusüsteemi turvalisust tagada
        100%, mistõttu ei saa me garanteerida teabe absoluutset turvalisust.
        Soovitame teil hoolitseda veebis teie valduses olevate isikuandmete eest
        ja looma oma SMS-kontole tugevad salasõnad, piirama ligipääsu oma
        arvutile ja veebilehitsejale, logides välja pärast oma sessiooni
        lõpetamist ning vältima meile tundliku teabe edastamist, mille
        avaldamine võib teie arvates tekitada teile olulist kahju.
      </p>
      <p>
        Kõik meie volitatud töötajad, kes on seotud teie poolt meile antud teie
        ja kolmandate isikute isikuandmete töötlemisega, on sidunud end
        konfidentsiaalsuskohustusega ning nad ei töötle muul moel teie
        isikuandmeid ilma teie volituseta, kui see ei toimu teile meie teenuste
        osutamise eesmärgil.
      </p>
      <p>
        Isikuandmete edastamine kolmandatesse riikidesse (st riikidesse, mis ei
        ole Euroopa Liidu liikmesriigid ega ole ühinenud Euroopa
        Majanduspiirkonna kokkuleppega) on lubatud ainult Kliendi nõusolekul,
        välja arvatud juhul, kui seadus sätestab teisiti.
      </p>
      <h3>Andmesubjekti õigused</h3>
      <p>
        Teil on õigus nõuda ligipääsu oma isikuandmetele ning nõuda oma
        isikuandmete korrigeerimist, täien­damist, kustutamist, ülekandmist või
        kasutamise piiramist, pöördudes meie poole kasutades allpool toodud
        kontaktandmeid. Juhul, kui tei on alust arvata, et me oleme
        seadusevastaselt töödelnud teie isikuandmeid, on teil õigus esitada
        kaebus kasutades alltoodud kontaktandmeid või pöörduda
        andmekaitseinspektsiooni poole.
      </p>
      <p>
        Selleks, et andmesubjekti õigusi kasutada, tuleb saata digiallkirjaga
        taotlus aadressile{' '}
        <a href="mailto:dpo@messenger.ee">dpo(at)messenger.ee</a>
      </p>
      <h3>Kolmandad isikud</h3>
      <p>
        Teenuste osutamise huvides teeme koostööd kolmandate isikutega, kellega
        me võime jagada isikuandmeid Teenuste toetamiseks. Isikuandmeid võidakse
        jagada kolmandate isikutega, et tagada SMS sõnumite kättetoimetamine,
        veebimajutus ja serveriteenused, kommunikatsioon ja sisuedastamise
        võrgustikud (CDN), andme- ja küberturbeteenused, arveldamine ja maksete
        töötlemise teenused, pettuste avastamise ja vältimise teenused,
        veebianalüütika, e-posti kättetoimetamise ja seireteenused, sessiooni
        registreerimise- ja turundusteenused. Kolmandatest isikutest
        teenuseosutajad võivad saada vaid minimaalse hulga isikuandmeid, mis on
        vajalikud sõltuvalt nende konkreetsetest ülesannetest meie teenuste ja
        äritegevuse võimaldamisel ja toetamisel ning nad võivad neid kasutada
        ainult vastavatel eesmärkidel. Me võime jagada isikuandmeid
        kolman­datest isikutest teenuseosutajatega, kellega meil on lepingulised
        suhted ja kes on kohustunud järgima andmekaitseseaduses sätestatud
        kohustusi.
      </p>
      <p>
        Meie Teenuste kasutamisega ettevõtte või muu juriidilise isiku heaks
        ja/või kui Teenuseid kasutatakse kolmandate isikute isikuandmete
        töötlemiseks, annate te meile tagasivõtmatu nõusoleku kasutada Teenuste
        osutamise eesmärkidel Kolmandast isikust teenuseosutajat meie oma
        ära­nägemisel.
      </p>
      <p>
        Direct Messenger on vastutav isikuandmete töötlemise eest, mida teevad
        Kolmandatest isikutest teenuseosutajad, kelle Direct Messenger on
        palganud vastava andmetöötluse jaoks kooskõlas kohaldatavate seadustega.
        Teatud asjaoludel võidakse meilt nõuda ka teabe jagamist kolmandate
        isikutega, et täita õigusnõudeid või vastata ametivõimude seaduslikele
        nõudmistele, aga ka meie või kolmandate isikute seadusjärgsete huvide
        kaitsmiseks.
      </p>
      <h3>Säilitamistähtajad</h3>
      <p>
        Me võime säilitada teie ja kolmandate isikute isikuandmeid, mis te olete
        meile edastanud, kuni teie suhe meiega on aktiivne, omades kontot või
        nagu on muul moel vajalik teile meie Teenuste osutamiseks.
      </p>
      <p>
        Pärast kliendisuhte lõppemist, vastavalt Kliendi valikule, me kas
        kustutame kõik isikuandmed või tagastame need Kliendile pärast
        töötlemist hõlmavate teenuste osutamise lõppemist ning kustutame
        olemasolevad koopiad, välja arvatud juhul, kui kohaldatav seadus nõuab
        isikuandmete säilitamist. Näiteks võime me jätkata teie ja kolmandate
        isikute isikuandmete, mille te meile olete andnud, koopiate hoiustamist,
        nagu on põhjendatult vajalik meie seadusjärgsete kohustuste täitmiseks,
        teie ja meie vahel vaidluste lahenda­miseks, pettuse ja kuritarvitamise
        vältimiseks, meie lepingute jõustamiseks ja/või meie või kolmandate
        isikute õigustatud huvide kaitsmiseks.
      </p>
      <p>
        Kõiki isikuandmeid, mis on seotud konto üksikasjadega, näiteks, kuid
        mitte ainult: maksete andmed ja ajalugu, teave kasutajakonto kohta ja
        kontole üleslaaditud teave (telefoninumbrid), säilitatakse konto
        aktiivse kasutamise jooksul või seni, kuni andmesubjektid nõuavad
        andmete kustutamist, välja arvatud siis, kui seadus sätestab teisiti.
      </p>
      <p>
        Kõiki kasutaja tegevuste logisid hoitakse kolm (3) kalendriaastat +
        jooksev aasta, et tagada teenuse aruanded ja teenuse kasutamise ajalugu
        või kuni andmesubjektide vastava nõudeni andmete kustutamise kohta (v.a
        juhul, kui seadus sätestab teisiti).
      </p>
      <h3>Muudatused</h3>
      <p>
        Me võime aeg-ajalt käesolevaid andmekaitsetingimusi muuta juhtudel, kui
        me viime sisse uusi teenuseid või funktsioone. Käesolevate
        andmekaitsetingimuste muudatused jõustuvad ja neid kohaldatakse hetkest,
        mil need on üles laetud <Link to="/privacy-policy">veebilehele</Link>
      </p>
      <p>
        Seetõttu soovitame teil aeg-ajalt seda lehte vaadata. Meie teenuste
        kasutamise jätkamisega või muul moel meile isikuandmete andmisega pärast
        seda, kui selle eeskirja parandused on rakendatud, nõustute te
        andmekaitsetingimuste uuendatud versiooniga.
      </p>
      <h3>Kontaktandmed</h3>
      <p>
        Teie küsimused isikuandmete töötlemise või käesolevate
        andmekaitsetingimuste kohta on teretulnud. Palun võtke meiega ühendust
        allolevatel kontaktidel:{' '}
        <a href="mailto:support@messenger.ee" className="is-block">
          support(at)messenger.ee
        </a>{' '}
        <a href="tel:+37255562773" className="is-block">
          tel +372 5556 2773
        </a>{' '}
      </p>
    </div>
  );
};

export default Et;
