import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import './PrivacyPolicy.scss';

const En: React.FC = (): ReactElement => {
  return (
    <div className="En">
      <h3>Privacy Policy</h3>
      <p>
        Direct Messenger OÜ respects the privacy and is fully committed to
        protect personal data and use it in accordance with legal obligations.
        Following Privacy Policy describes how we may process personal data and
        the rights available of our Customers and Data Subjects. Direct
        Messenger will process personal data only for the purposes set forth in
        this Privacy Policy.{' '}
      </p>
      <p>
        By using any of our Services or by accessing and using the messaging
        platform, you signify your approval of the terms set out in this Privacy
        Policy, Terms & Conditions and other policies posted on our platform.
        Customer and Direct Messenger shall observe and comply with all
        applicable laws regarding personal data processing and protection.{' '}
      </p>
      <h3>Data processing</h3>
      <p>
        For us to provide our Services, to confirm your identity, contact you
        and invoice you, we collect such data as name, surname, company’s name,
        e-mail address and phone number, payment details and other information
        you provide us.
      </p>
      <p>
        To be able to provide support for our Services, to better serve you and
        for the improvement or implementation of our Services, we process data
        on your account for example, phone numbers, message content and other
        information connected to it which you as a Customer have chosen to
        process in relation to the Services provided to you, which are indicated
        in more detail on your respective account.
      </p>
      <p>
        As you are processing personal data in connection with our Services
        (e.g. you deliver messages on behalf of a company or other person), you
        represent that you have acquired all necessary consents or have other
        legal basis for the processing of personal data, including but not
        limited for the processing of all respective data by Direct Messenger
        and other third parties used by Direct Messenger for the provision of
        services.
      </p>
      <p>
        Your e-mail address or phone number will be processed to send you
        notifications that are essential for providing our Services.{' '}
      </p>
      <p>
        To ensure a smooth customer support experience, we process information
        that you provide on our platform, in e-mails or through other means of
        communication you have used. We use this information to provide and
        enhance our Services and answer any questions you may have.{' '}
      </p>
      <h3>Data security</h3>
      <p>
        We use reasonable and appropriate organizational, technical and
        administrative measures in accordance with applicable law to protect the
        confidentiality, integrity and availability of personal data.
      </p>
      <p>
        Databases with personal information are stored on servers protected by
        firewalls, passwords and other necessary technical solutions to protect
        our servers from unauthorized system access, allowing only trusted
        personnel to manage our systems. Use of necessary security measures when
        accessing and handling the data is required. Security copies of
        databases are held in locked locations accessible only by authorized
        persons.
      </p>
      <p>
        Direct Messenger uses HTTPS connection, which means that the computer
        connection with our system is encrypted. The field of the Internet
        browser with a padlock icon indicates a secure connection. To verify the
        authenticity of the certificate press the padlock icon.
      </p>
      <p>
        Unfortunately, no data transmission or storage system is guaranteed to
        be 100% secure, therefore we cannot guarantee absolute security of
        information. We encourage you to take care of the personal data in your
        possession that you process online and set strong passwords for your SMS
        account, limit access of your computer and browser by signing off after
        you have finished your session. Avoid providing us with any sensitive
        information which disclosure you believe could cause you substantial
        harm.
      </p>
      <p>
        All of our authorized personnel involved in the processing of your and
        third persons’ personal data, that you have provided us, have committed
        themselves to confidentiality obligations and shall not access or
        otherwise process your personal data without your authorization if it is
        not for the purposes of providing you our services.
      </p>
      <p>
        Transfer of personal data to third countries (i.e. countries which are
        not Members of European Union or not incorporated in the Agreement on
        the European Economic Area) is only allowed with the consent of the
        Customer unless otherwise provided by law.
      </p>
      <h3>Rights of Data Subject</h3>
      <p>
        You have the right to request access to your personal data and to
        correct, amend, delete, transfer or limit the use of your personal data
        by reaching us using the contact information provided below.
        Furthermore, if you believe that we have unlawfully processed your
        personal data you have the right to submit a complaint to the contact
        information provided below or to respective data protection supervisory
        authority – the Data Protection Inspectorate.
      </p>
      <p>
        In order to exercise the rights of the data subject, a corresponding
        digitally signed application must be sent to the address:{' '}
        <a href="mailto:dpo@messenger.ee">dpo(at)messenger.ee</a>
      </p>
      <h3>Third parties</h3>
      <p>
        In order to provide you our Services we work with third parties with
        whom we may share personal data to support the Services. Personal data
        may be shared with third parties to provide SMS delivery, hosting and
        server co-location services, communications and content delivery
        networks (CDN), data and cyber security services, billing and payment
        processing services, fraud detection and prevention services, web
        analytics, email distribution and monitoring services, session recording
        services and marketing services. The Third Party Service Providers may
        only receive the minimum amount of personal data necessary, depending on
        their particular roles and purposes in facilitating and enhancing our
        services and business, and may only use it for such purposes. We may
        share personal data to Third Party Service Providers that we have
        contractual relations with, undertaken to comply with obligations set
        out in applicable data protection laws.{' '}
      </p>
      <p>
        While using the Services on behalf of a company or other legal entity
        and/or the Services are used to process personal data of third persons,
        you are providing us irrevocable consent to use any Third Party Service
        Provider at our discretion for the purposes of providing the Services.{' '}
      </p>
      <p>
        Direct Messenger remains responsible for the processing of personal data
        carried out by Third Party Service Providers that Direct Messenger has
        engaged with for respective data processing in accordance with
        applicable laws. In certain circumstances, we may also be required to
        share information with third parties to conform to legal requirements or
        to respond to lawful requests by public authorities as well as to
        protect our, or a third party’s lawful interests.{' '}
      </p>
      <h3>Retention periods</h3>
      <p>
        We may retain your personal data and third parties’ personal data you
        have provided us for as long as your relationship with us is active by
        having an account or as otherwise needed to provide you our Services.
      </p>
      <p>
        After terminating your relationship with us, at the choice of the
        Customer we will delete or return all the personal data to the Customer
        after the end of the provision of services relating to processing, and
        delete existing copies unless applicable law requires storage of the
        personal data. For example, we may continue to store copies of your and
        third persons’ personal data you have provided to us, as reasonably
        necessary to comply with our legal obligations, to resolve disputes
        between you and us, to prevent fraud and abuse, to enforce our
        agreements, and / or to protect our or third parties’ legitimate
        interests.{' '}
      </p>
      <p>
        All personal data relating to account details such as but not limited
        to: payment details and history, user account information and uploaded
        information (such as telephone numbers) are retained for the duration of
        the active use of the account or until the data subjects request for
        data deletion, unless required otherwise by law.
      </p>
      <p>
        All user activity logs are kept for three (3) calendar years + current
        year to provide service reports and service use history, or until the
        data subjects request for data deletion, unless required otherwise by
        law.
      </p>
      <h3>Privacy Policy changes</h3>
      <p>
        We may occasionally amend this Privacy Policy, in cases when we
        introduce new services or new features. The amendments to this Privacy
        Policy enter into force and are applied from the moment they have been
        uploaded to the <Link to="/privacy-policy">page</Link>
      </p>
      <p>
        Therefore, we encourage you to check this page from time to time. By
        continuing to use our services or otherwise providing personal data to
        us, after the amendments to this policy have been implemented, you agree
        to the updated terms of Privacy Policy.{' '}
      </p>
      <h3>Contact information</h3>
      <p>
        Your questions regarding the processing of your personal data or to the
        Privacy Policy are welcome. Please contact us:{' '}
        <a href="mailto:support@messenger.ee" className="is-block">
          support(at)messenger.ee
        </a>{' '}
        <a href="tel:+37255562773" className="is-block">
          tel +372 5556 2773
        </a>
      </p>
    </div>
  );
};

export default En;
