import React, { ReactElement, ReactNode } from 'react';
import './SubPageHero.scss';

type Props = {
  children?: ReactNode;
  image?: string;
};

const SubPageHero = ({ children, image }: Props): ReactElement => {
  return (
    <div className="SubPageHero">
      <div className="columns">
        <div className="column is-two-thirds content-column">
          <div>{children}</div>
        </div>
        <div
          className="column is-one-third image-column"
          style={{ backgroundImage: `url(${image})` }}
        ></div>
      </div>
    </div>
  );
};

export default SubPageHero;
