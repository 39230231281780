import React, { ReactElement } from 'react';
import './Developers.scss';
import Sidebar from './Sidebar';
import DevSmsApi from './DevSmsApi';
import DevTwoFactor from './DevTwoFactor';
import DevGateway from './DevGateway';
import DevPersonal from './DevPersonal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';

const Developers: React.FC = (): ReactElement => {
  return (
    <div className="Developers" id="developers-top">
      <div className="columns">
        <div className="column is-2">
          <Sidebar />
        </div>
        <div className="column is-8 content-column">
          <DevSmsApi />
          <DevTwoFactor />
          <DevPersonal />
          <DevGateway />
        </div>
      </div>
      <a href="#developers-top" className="to-top-button">
        <FontAwesomeIcon icon={faArrowUp} />
      </a>
    </div>
  );
};

export default Developers;
