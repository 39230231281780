import React, { ReactElement, useEffect, useState } from 'react';
import './Webcare.scss';
import SubPageHero from '../../../components/SubPageHero/SubPageHero';
import HeroImage from '../../../assets/images/hero/cactus.jpg';
import SecondaryNav from '../../../components/SecondaryNav/SecondaryNav';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { sentenceCase, upperCase } from 'converting-case';

const Webcare: React.FC = (): ReactElement => {
  const { t } = useTranslation();
  const getLanguage = () => i18n.language || window.localStorage.i18nextLng;

  return (
    <div className="Webcare">
      <SecondaryNav />
      <SubPageHero image={HeroImage}>
        <h2>{sentenceCase(t('Webcare.header'))}</h2>
        <p className="smaller-padding">
          {t('Webcare.description part 1')}{' '}
          <a className="text-link" href="/our-works">
            {t('Webcare.description link')}
          </a>
        </p>
        <p className="smaller-padding">{t('Webcare.description part 2')}</p>
        <p>{t('Webcare.description part 3')}</p>

        <a href="mailto:support@messenger.ee" className="button is-outlined">
          {upperCase(t('contact us'))}
        </a>
      </SubPageHero>
      <div id="webcare-example">
        <div className="columns">
          <div className="column image-column">
            <div className="columns">
              <div className="column work-1">
                <a
                  href="https://cci-collaboration.org/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="overlay">
                    <p>
                      Construction Classification International Collaboration
                      (CCIC) - cci-collaboration.org
                    </p>
                  </div>
                </a>
              </div>
              <div className="column work-2">
                <a
                  href="https://ehupe.eehitus.ee/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="overlay">
                    <p>Kliimaministeerium ehituse e-hüpe - ehupe.eehitus.ee</p>
                  </div>
                </a>
              </div>
            </div>
            <div className="columns">
              <div className="column work-3">
                <a href="https://estsof.ee/" target="_blank" rel="noreferrer">
                  <div className="overlay">
                    <p>Erioperatsioonide väejuhatus - estsof.ee</p>
                  </div>
                </a>
              </div>
              <div className="column work-4">
                <a
                  href="https://proisolatsioon.ee/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="overlay">
                    <p>Proisolatsioon OÜ - proisolatsioon.ee</p>
                  </div>
                </a>
              </div>
            </div>
            <div className="columns">
              <div className="column work-5">
                <a
                  href="https://saksakevad.ee/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="overlay">
                    <p>Saksa Suursaatkond - saksakevad.ee</p>
                  </div>
                </a>
              </div>
              <div className="column work-6">
                <a href="https://combifood.ee" target="_blank" rel="noreferrer">
                  <div className="overlay">
                    <p>Combifood - combifood.ee</p>
                  </div>
                </a>
              </div>
            </div>
            <div className="columns">
              <div className="column work-7">
                <a
                  href="https://kordumatu.ee/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="overlay">
                    <p>Producer OÜ - kordumatu.ee</p>
                  </div>
                </a>
              </div>
              <div className="column work-8">
                <a href="https://kravmaga.ee/" target="_blank" rel="noreferrer">
                  <div className="overlay">
                    <p>Starport OÜ - kravmaga.ee</p>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="column content-column">
            <h3>{t('Webcare.how we can help')}</h3>
            <p>
              <span className="bold">
                {t('Webcare.example description part 1 bold')}:{' '}
              </span>
              {t('Webcare.example description part 1')}
            </p>
            <p>
              <span className="bold">
                {t('Webcare.example description part 2 bold')}:{' '}
              </span>
              {t('Webcare.example description part 2')}
            </p>
            <p>
              <span className="bold">
                {t('Webcare.example description part 3 bold')}:{' '}
              </span>
              {t('Webcare.example description part 3')}
            </p>
            <p>
              <span className="bold">
                {t('Webcare.example description part 4 bold')}:{' '}
              </span>
              {t('Webcare.example description part 4')}
            </p>
            <p>
              <span className="bold">
                {t('Webcare.example description part 5 bold')}:{' '}
              </span>
              {t('Webcare.example description part 5')}
            </p>
            <p>{t('Webcare.example description general')}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Webcare;
