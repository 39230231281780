import React, { ReactElement } from 'react';
import './Home.scss';
// import ClientTicker from '../../components/ClientTicker/ClientTicker';
import PersonalSteps from '../../components/PersonalSteps/PersonalSteps';
// import { Parallax } from 'react-scroll-parallax';
import SwiperCore, { Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import { upperCase } from 'converting-case';
import cityMotors from '../../assets/images/endorsment_clients/cityMotors.png';
import fazer from '../../assets/images/endorsment_clients/fazer.png';
import kuldan from '../../assets/images/endorsment_clients/kuldan.png';
import megazone from '../../assets/images/endorsment_clients/megazone.jpg';
import pepsico from '../../assets/images/endorsment_clients/pepsico.png';
import salva from '../../assets/images/endorsment_clients/salva.png';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

SwiperCore.use([Pagination, Autoplay]);

const Home: React.FC = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <div className="Home">
      <div className="hero-wrapper">
        <div className="hero kenburns-left"></div>
        <div className="hero-content">
          <h2>{t('Home.We deliver your message')}</h2>
          <span>SMS • PIN • 2FA • HLR • RCS • API</span>
          <span className="has-text-centered">
            <a
              className="button is-primary has-tooltip-bottom has-tooltip-arrow has-tooltip-info"
              href="https://smart.messenger.ee/register"
              target="_blank"
              rel="noreferrer"
              data-tooltip={t('Home.smart account tooltip')}
            >
              {upperCase(t('smart account'))}
            </a>
            <Link
              to="/services/personal-messages"
              className="button is-primary has-tooltip-bottom has-tooltip-arrow has-tooltip-info"
              data-tooltip={t('Home.personal account tooltip')}
            >
              {upperCase(t('personal account'))}
            </Link>
            <a
              className="button is-primary has-tooltip-bottom has-tooltip-arrow has-tooltip-info"
              href="mailto:support@messenger.ee"
              data-tooltip={t('Home.premium account tooltip')}
            >
              {upperCase(t('premium account'))}
            </a>
          </span>
        </div>
      </div>
      {/* <ClientTicker /> */}
      <PersonalSteps />
      <div className="columns no-bottom-margin" id="messaging">
        <div className="column image-column first"></div>
        <div className="column">
          {/* <Parallax className="custom-class" y={[-20, 20]} tagOuter="figure"> */}
          <div className="content-block">
            <h2>{t('Home.SMS platform')}</h2>
            <p>{t('Home.sms platform description')}</p>
            <span>
              <a
                className="button is-primary"
                href="https://smart.messenger.ee/register"
                target="_blank"
                rel="noreferrer"
              >
                {upperCase(t('get started'))}
              </a>
              <Link
                to="/services/messaging"
                className="button is-primary is-outlined"
              >
                {upperCase(t('read more'))}
              </Link>
            </span>
          </div>
          {/* </Parallax> */}
        </div>
      </div>
      <div className="columns no-bottom-margin" id="webcare">
        <div className="column">
          {/* <Parallax className="custom-class" y={[-20, 20]} tagOuter="figure"> */}
          <div className="content-block">
            <h2>{t('Home.Webcare')}</h2>
            <p>{t('Home.webcare description')}</p>
            <span>
              <a className="button is-primary" href="#footer">
                {upperCase(t('write us'))}
              </a>
              <Link
                to="/services/webcare"
                className="button is-primary is-outlined"
              >
                {upperCase(t('read more'))}
              </Link>
            </span>
          </div>
          {/* </Parallax> */}
        </div>
        <div className="column image-column second"></div>
      </div>
      <div className="columns no-bottom-margin" id="hlr">
        <div className="column image-column third"></div>
        <div className="column">
          {/* <Parallax className="custom-class" y={[-20, 20]} tagOuter="figure"> */}
          <div className="content-block">
            <h2>{t('Home.HLR lookup')}</h2>
            <p>{t('Home.hlr lookup description')}</p>
            <span>
              <a className="button is-primary" href="#footer">
                {upperCase(t('write us'))}
              </a>
              <Link
                to="/services/hlr"
                className="button is-primary is-outlined"
              >
                {upperCase(t('read more'))}
              </Link>
            </span>
          </div>
          {/* </Parallax> */}
        </div>
      </div>
      <div className="columns no-bottom-margin" id="campaign-solutions">
        <div className="column">
          {/* <Parallax className="custom-class" y={[-20, 20]} tagOuter="figure"> */}
          <div className="content-block">
            <h2>{t('Home.Campaign solutions')}</h2>
            <p>{t('Home.campaign solutions description')}</p>
            <span>
              <a className="button is-primary" href="/our-works">
                {upperCase(t('our works'))}
              </a>
              <Link
                to="/services/campaign-solutions"
                className="button is-primary is-outlined"
              >
                {upperCase(t('read more'))}
              </Link>
            </span>
          </div>
          {/* </Parallax> */}
        </div>
        <div className="column image-column fourth"></div>
      </div>
      <div className="endorsements">
        <Swiper
          centeredSlides={true}
          loop={true}
          pagination={{ clickable: true }}
          autoplay={{ delay: 10000 }}
          // onSlideChange={() => console.log('slide change')}
          // onSwiper={(swiper) => console.log(swiper)}
        >
          <SwiperSlide>
            <div className="endorsement-slide-content">
              <img src={pepsico} alt="pepsico logo" className="client-image" />
              <p className="endorsement-text">
                {t('Home.pepsico endorsement')}
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="endorsement-slide-content">
              <img
                src={megazone}
                alt="megazone logo"
                className="client-image"
              />
              <p className="endorsement-text">
                {t('Home.megazone endorsement')}
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="endorsement-slide-content">
              <img src={salva} alt="salva logo" className="client-image" />
              <p className="endorsement-text">{t('Home.salva endorsement')}</p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="endorsement-slide-content">
              <img src={fazer} alt="fazer logo" className="client-image" />
              <p className="endorsement-text">{t('Home.fazer endorsement')}</p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="endorsement-slide-content">
              <img
                src={cityMotors}
                alt="cityMotors logo"
                className="client-image"
              />
              <p className="endorsement-text">
                {t('Home.cityMotors endorsement')}
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="endorsement-slide-content">
              <img src={kuldan} alt="kuldan logo" className="client-image" />
              <p className="endorsement-text">{t('Home.kuldan endorsement')}</p>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

export default Home;
