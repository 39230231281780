import React, { FormEvent, ReactElement, useState } from 'react';
import './Developers';
import { CopyBlock, atomOneLight } from 'react-code-blocks';
import codeblocks from './codeblocks';

const DevSmsApi: React.FC = (): ReactElement => {
  const [dlrExampleOutput, setDlrExampleOutput] =
    useState<string>('Example output');

  const dlrExampleReplace = (event: FormEvent<HTMLInputElement>) => {
    event.preventDefault();
    console.log(event.currentTarget.value);
    const dlruri = event.currentTarget.value;
    if (dlruri.includes('%smsid%') && dlruri.includes('%dlr%')) {
      let output = dlruri
        .replace('%smsid%', 'KLIENT_1')
        .replace('%dlr%', '8')
        .replace('%receiver%', '37255555555')
        .replace('%time%', '2021-04-23%2009:00:00');
      setDlrExampleOutput(output);
    } else {
      setDlrExampleOutput(
        'DLRURI requires at least %smsid% and %dlr% variables.'
      );
    }
  };

  return (
    <div className="DevSmsApi" id="dev-sms-api">
      <h2>SMS API V2</h2>
      <p>
        Thank you for choosing Direct Messenger API. This documentation will
        help you set up the API connection and explains different options
        available to you.
      </p>
      <div id="dev-sms-api-how-to-get-started">
        <h3>How to get started</h3>
        <div id="dev-sms-api-how-to-get-started-step-1">
          <h4>1. Get an account</h4>
          <p>
            To send messages using our API you first need a messaging account.
            You can create a Smart account on our website here:{' '}
            <a
              href="https://smart.messenger.ee/register"
              target="_blank"
              rel="noreferrer"
            >
              smart.messenger.ee
            </a>
            . To get a Premium account you need to contact us on{' '}
            <a href="mailto:support@messenger.ee">support@messenger.ee.</a>
          </p>
        </div>
        <div id="dev-sms-api-how-to-get-started-step-2">
          <h4>2. Set up connection using API KEY</h4>
          <p>
            The next step is to setup your connection to our server. We use an
            API KEY to authenticate all our incoming request. If you do not have
            an API key or you have lost it feel free to{' '}
            <a href="mailto:support@messenger.ee">contact us</a>. For Smart
            account the API KEY will be generated on your account right after
            the credit has been added.
          </p>
        </div>
        <h5>Setup</h5>
        <p>
          The setup is fairly simple if you are familiar with <code>POST</code>{' '}
          requests. The most basic request to send a text message through our
          API requires the following parameter:
        </p>
        <ul>
          <li>
            <code>api_token</code> - Your API key
          </li>
          <li>
            <code>smstext</code> - Message that you want to send
          </li>
          <li>
            <code>receiver</code> - The receiving number
          </li>
          <li>
            <code>sender</code> - Name displayed for the recipient
          </li>
        </ul>
        <p>
          We will explain some of those parameters in more detail further down
          and go over some common mistakes/misunderstandings.
        </p>
        <p>
          <strong>PS! Every request should use UTF-8 encoding</strong>{' '}
        </p>
        <p>Here's what a basic request like that might look like:</p>
        <p>
          {/* <code>
            POST
            https://API_URL/?api_token=YOUR_API_KEY&smstext=Foo&receiver=372555555555&sender=Bar
          </code> */}
          <div className="code-block-wrapper">
            <CopyBlock
              text={
                'POST https://API_URL/?api_token=YOUR_API_KEY&smstext=Foo&receiver=372555555555&sender=Bar'
              }
              showLineNumbers={true}
              theme={atomOneLight}
              wrapLines={true}
              codeBlock
            />
          </div>
        </p>
        <h5>Example</h5>
        <p>
          A basic request using <code>PHP curl</code>
        </p>
        <div className="code-block-wrapper">
          <CopyBlock
            language="php"
            text={codeblocks['DevSmsApiExample1']}
            showLineNumbers={true}
            theme={atomOneLight}
            wrapLines={true}
            codeBlock
          />
        </div>
      </div>
      <div id="dev-sms-api-available-parameters">
        <h3>Available parameters</h3>
        <table className="table is-bordered">
          <thead>
            <tr>
              <th>Attribute</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <code>api_token </code>
              </td>
              <td>
                Your API key, provided by us, used to authenticate each request.
              </td>
            </tr>
            <tr>
              <td>
                <code>smstext</code>
              </td>
              <td>
                Message you want to send. For the best results avoid using any
                special unicode characters. Refer to this article. If no special
                characters are used the Message limit will be 160 characters for
                one message. If the character limit is exceeded the message will
                be sent as two SMS-s and now each message is maximum of 157
                characters. Up to 3 messages can be sent at once. If you do need
                to use special character the limit for one SMS is 70 characters
                and 67 characters if you exceed one message.
              </td>
            </tr>
            <tr>
              <td>
                <code>receiver</code>
              </td>
              <td>
                Recipient of the message. Needs to be a phone number and include
                country code.
                <br />
                Example: 37255555555
              </td>
            </tr>
            <tr>
              <td>
                <code>sender</code>
              </td>
              <td>
                Name or number displayed for the recipient. Can be either fully
                numeric, or alphanumeric. <br />
                <b>Numeric:</b> If sender starts with <code>+</code> and
                contains only numbers (<code>0123456789</code>), then it is
                treated as a numeric sender name. Numeric sender names can be up
                to 15 characters in length and your recipient can reply to that
                number, which allows for two way communication. Numeric sender
                names MUST begin with international prefix <code>+</code>+ and a
                country code (for example <code>+372</code> for Estonia).
                Examples of numeric sender names are: <code>+37251000000</code>,
                <code>+358500000000</code>. Please note that having spaces in
                your sender name, will change it to alphanumeric sender (for
                example, <code>+372 51000000</code> will be treated as
                alphanumeric sender). <br />
                <b>Alphanumeric:</b> If sender contains any character other than
                <code>+0123456789</code>, then it will be treated as
                alphanumeric sender name. Alphanumeric sender names can contain
                all characters, but they can only be up to 11 characters in
                length. Recipients can not reply to alphanumeric sender names.
                Examples of alphanumeric sender names are name of a person or
                brand <code>John Smith</code>, <code>Apple</code>.
              </td>
            </tr>
            <tr>
              <td>
                <code>conversion</code>
                <span className="italic attr">optional</span>
              </td>
              <td>
                Optional parameter to specify conversion level for the
                <code>smstext</code>. <br />0 -{' '}
                <em>no conversion is made, message is sent as is</em>. <br />1 -{' '}
                <em>
                  Default conversion level. Some characters are converted but
                  special characters still get through.
                </em>{' '}
                <br />2 -{' '}
                <em>
                  Harsh conversion. All special characters are converted and
                  what can't be converted are thrown out
                </em>
                .
              </td>
            </tr>
            <tr>
              <td>
                <code>deliverytime</code>
                <span className="italic attr">optional</span>
              </td>
              <td>
                Used to specify the time Message should be sent.
                <br />
                Needs to be in format <code>YYYY-MM-DD HH:II:SS</code>
                <br />
                Example: <code>'deliverytime':'2019-12-30 15:30:00'</code>
              </td>
            </tr>
            <tr>
              <td>
                <code>dlruri</code>
                <span className="italic attr">optional</span>
              </td>
              <td>
                URL you want to receive delivery reports to. Delivery reports
                are responses from operators whether they were able to deliver a
                message or not. Since this may not be instant we will provide
                you an unique ID for every message so you can later match each
                message with a DLR. ID-s are in the UUID format.
                <br />
                <strong>dlruri parameters</strong>:{' '}
                <code>id=%smsid%, dlr=%dlr%, receiver=%receiver%</code>
                <span className="italic">(optional)</span>,{' '}
                <code>time=%time%</code>{' '}
                <span className="italic">(optional)</span>
                <br />
                Our backend will replace these parameters with corresponding
                values, which you can test in the example below.
                <br />
                Example:{' '}
                <code>https://www.your-dlr-url.com?dlr=%dlr%&id=%smsid%</code>
              </td>
            </tr>
            <tr>
              <td>
                <code>dlrid</code>
                <span className="italic attr">optional</span>
              </td>
              <td>
                This parameter can only be used alongside <code>dlruri</code>{' '}
                parameter. The parameter is used to specify a custom ID for
                DLR-s that you can later match with your messages. If this
                parameter is not specified we will provide an ID in the UUID
                format for you.
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div id="dlr-example">
        <h4>DLR Interactive Example</h4>
        <label>Your DLRURI + parameters</label>
        <input
          className="input"
          type="text"
          onChange={dlrExampleReplace}
          placeholder="https://www.your-dlr-url.com?dlr=%dlr%&id=%smsid%"
        />
        <label>Request will be made to</label>
        <input
          className="input dlr-example-output"
          type="text"
          value={dlrExampleOutput}
        />
      </div>
      <div id="dev-sms-api-responses">
        <h3>Responses</h3>
        <h4>Success</h4>
        <p>
          After a request, a response is generated. If everything went smoothly
          you will be notified with a response. There are two possible success
          responses, one for when you did not include <code>dlruri</code> and
          one for when you did. The only difference is if you used{' '}
          <code>dlruri</code> parameter will will send you back an ID alongside
          the usual success response.
        </p>
        <h5>Example</h5>
        <p>
          Success response without <code>dlruri</code>
        </p>
        <div className="code-block-wrapper">
          <CopyBlock
            language="json"
            text={codeblocks['DevSmsApiResponse1']}
            showLineNumbers={true}
            theme={atomOneLight}
            wrapLines={true}
            codeBlock
          />
        </div>
        <p>
          Success response with <code>dlruri</code>
        </p>
        <div className="code-block-wrapper">
          <CopyBlock
            language="json"
            text={codeblocks['DevSmsApiResponse2']}
            showLineNumbers={true}
            theme={atomOneLight}
            wrapLines={true}
            codeBlock
          />
        </div>
        <p>Success response with a warning message</p>
        <div className="code-block-wrapper">
          <CopyBlock
            language="json"
            text={codeblocks['DevSmsApiResponse3']}
            showLineNumbers={true}
            theme={atomOneLight}
            wrapLines={true}
            codeBlock
          />
        </div>
        <h4>Failure</h4>
        <p>
          If your request fails we will send you back a response with a
          description that might indicate to where the process failed and what
          needs to be done in order to solve the issue.
        </p>
        <h5>Example</h5>
        <p>Some examples of unsuccessful responses</p>
        <p>
          The included <code>dlruri</code> was not in a correct <code>URL</code>{' '}
          format
        </p>
        <div className="code-block-wrapper">
          <CopyBlock
            language="json"
            text={codeblocks['DevSmsApiResponse4']}
            showLineNumbers={true}
            theme={atomOneLight}
            wrapLines={true}
            codeBlock
          />
        </div>
        <p>
          The <code>receiver</code> number was invalid
        </p>
        <div className="code-block-wrapper">
          <CopyBlock
            language="json"
            text={codeblocks['DevSmsApiResponse5']}
            showLineNumbers={true}
            theme={atomOneLight}
            wrapLines={true}
            codeBlock
          />
        </div>
      </div>
      <div id="dev-sms-api-examples">
        <h3>Examples</h3>
        <p>Examples of a request using all the available parameters.</p>
        <h4>Requests</h4>
        <p>Basic request</p>
        <div className="code-block-wrapper">
          <CopyBlock
            language="php"
            text={codeblocks['DevSmsApiExample2']}
            showLineNumbers={true}
            theme={atomOneLight}
            wrapLines={true}
            codeBlock
          />
        </div>
        <p>
          Request using <code>x-www-form-urlencoded</code>
        </p>
        <div className="code-block-wrapper">
          <CopyBlock
            language="php"
            text={codeblocks['DevSmsApiExample3']}
            showLineNumbers={true}
            theme={atomOneLight}
            wrapLines={true}
            codeBlock
          />
        </div>
        <p>Request using query string</p>
        <div className="code-block-wrapper">
          <CopyBlock
            language="php"
            text={codeblocks['DevSmsApiExample4']}
            showLineNumbers={true}
            theme={atomOneLight}
            wrapLines={true}
            codeBlock
          />
        </div>
        <h4>Response</h4>
        <div className="code-block-wrapper">
          <CopyBlock
            language="json"
            text={codeblocks['DevSmsApiResponse6']}
            showLineNumbers={true}
            theme={atomOneLight}
            wrapLines={true}
            codeBlock
          />
        </div>
      </div>
      <div id="dev-sms-api-blacklist">
        <h3>Blacklist</h3>
        <p>
          Blacklist is used to store numbers you wish to exclude when sending
          messages.
        </p>
        <p>
          <b>
            NB! Adding a number to the blacklist does NOT mean that sending a
            message to the recipient gets automatically blocked. You will have
            to manually edit your send lists instead. This feature should be
            used just as a way to keep track of numbers, you wish to exclude in
            the future.
          </b>
        </p>
        <h4>Get your blacklist</h4>
        <p>
          To retrieve your blacklist make a <code>GET</code> request to{' '}
          <code>/api/blacklist</code>. Remember to include your API token as a
          Bearer token in your headers.
        </p>
        <h5>PHP cURL example</h5>
        <div className="code-block-wrapper">
          <CopyBlock
            language="php"
            text={codeblocks['DevBlacklistGetExample']}
            showLineNumbers={true}
            theme={atomOneLight}
            wrapLines={true}
            codeBlock
          />
        </div>
        <h5>Response</h5>
        <div className="code-block-wrapper">
          <CopyBlock
            language="json"
            text={codeblocks['DevBlacklistGetResponse']}
            showLineNumbers={true}
            theme={atomOneLight}
            wrapLines={true}
            codeBlock
          />
        </div>
        <h4>Add to blacklist</h4>
        <p>
          To add a number to your blacklist make a <code>POST</code> request to{' '}
          <code>api/blacklist/create</code>. The request needs to include{' '}
          <code>api_token</code> and <code>receiver</code> parameters.
        </p>
        <h5>PHP cURL example</h5>
        <div className="code-block-wrapper">
          <CopyBlock
            language="php"
            text={codeblocks['DevBlacklistAddExample']}
            showLineNumbers={true}
            theme={atomOneLight}
            wrapLines={true}
            codeBlock
          />
        </div>
        <h5>Response</h5>
        <div className="code-block-wrapper">
          <CopyBlock
            language="json"
            text={codeblocks['DevBlacklistAddResponse']}
            showLineNumbers={true}
            theme={atomOneLight}
            wrapLines={true}
            codeBlock
          />
        </div>
        <h4>Remove from blacklist</h4>
        <p>
          To remove a number from blacklist you need to make a <code>POST</code>{' '}
          request to{' '}
          <code>
            /api/blacklist/delete/{'{'}id{'}'}
          </code>{' '}
          where {'{'}id{'}'} is the ID of the number you are removing (you can
          get the ID-s by retrieving your blacklist as instructed above).
        </p>
        <h5>PHP cURL example</h5>
        <div className="code-block-wrapper">
          <CopyBlock
            language="php"
            text={codeblocks['DevBlacklistRemoveExample']}
            showLineNumbers={true}
            theme={atomOneLight}
            wrapLines={true}
            codeBlock
          />
        </div>
        <h5>Response</h5>
        <div className="code-block-wrapper">
          <CopyBlock
            language="json"
            text={codeblocks['DevBlacklistRemoveResponse']}
            showLineNumbers={true}
            theme={atomOneLight}
            wrapLines={true}
            codeBlock
          />
        </div>
      </div>
    </div>
  );
};

export default DevSmsApi;
