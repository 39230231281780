import axios from 'axios';
import React, {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { sentenceCase } from 'converting-case';
import './PriceList.scss';

type Props = { active: boolean; toggle: Dispatch<SetStateAction<boolean>> };

interface PriceObject {
  country: string;
  price: number;
  price_vat: number;
}

const PriceList = ({ active, toggle }: Props): ReactElement => {
  const { t } = useTranslation();

  const [prices, setPrices] = useState<PriceObject[]>();

  const getPricelist = async () => {
    axios
      .get('/api/pricelist')
      .then((response) => {
        setPrices(response.data.priceList);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getPricelist();
  }, []);
  return (
    <div className="PriceList">
      <div className={active ? 'modal is-active' : 'modal'}>
        <div className="modal-background" onClick={() => toggle(!active)}></div>
        <div className="modal-content">
          <h2>{sentenceCase(t('pricelist'))}</h2>
          <table className="table is-striped is-hoverable is-fullwidth">
            <thead>
              <tr>
                <th>{sentenceCase(t('country'))}</th>
                <th>{sentenceCase(t('price'))} (EUR)</th>
                <th>{t('price_vat')} (EUR)</th>
              </tr>
            </thead>
            <tbody>
              {prices?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.country}</td>
                    <td>{item.price}</td>
                    <td>{item.price_vat}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <button
          className="modal-close is-large"
          aria-label="close"
          onClick={() => toggle(!active)}
        ></button>
      </div>
    </div>
  );
};

export default PriceList;
