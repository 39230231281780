import React, { ReactElement } from 'react';
import './SmsApi.scss';
import SubPageHero from '../../../components/SubPageHero/SubPageHero';
import HeroImage from '../../../assets/images/hero/grid.jpg';
import SecondaryNav from '../../../components/SecondaryNav/SecondaryNav';
import { useTranslation } from 'react-i18next';
import { upperCase } from 'converting-case';

const SmsApi: React.FC = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <div className="SmsApi">
      <SecondaryNav />
      <SubPageHero image={HeroImage}>
        <h2>{upperCase(t('SmsApi.header'))}</h2>
        <p className="smaller-padding">{t('SmsApi.description part 1')}</p>
        <p className="smaller-padding">{t('SmsApi.description part 2')}</p>
        <p className="smaller-padding">{t('SmsApi.description part 3')}</p>
        <p>{t('SmsApi.description part 4')}</p>
        <a className="button is-outlined" href="mailto:support@messenger.ee">
          {upperCase(t('contact us'))}
        </a>
        <a
          className="button is-outlined"
          href="https://api.messenger.ee"
          target="_blank"
          rel="noreferrer"
        >
          {upperCase(t('documentation'))}
        </a>
      </SubPageHero>
    </div>
  );
};

export default SmsApi;
