import React, { ReactElement } from 'react';
import './Clients.scss';
import ClientImages from '../../assets/images/clients';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';

const Clients: React.FC = (): ReactElement => {
  return (
    <div className="Clients container is-max-widescreen">
      <ResponsiveMasonry columnsCountBreakPoints={{ 768: 2, 1024: 3, 1216: 4 }}>
        <Masonry className="masonry-wrapper">
          {ClientImages.map((image, index) => (
            <img
              key={index}
              className="masonry-image"
              src={image.body.default}
              alt="test"
            />
          ))}
        </Masonry>
      </ResponsiveMasonry>
    </div>
  );
};

export default Clients;
