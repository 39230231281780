import React, { ReactElement } from 'react';
import './SecondaryNav.scss';
import { NavLink as Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { sentenceCase, upperCase } from 'converting-case';

const SecondaryNav: React.FC = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <nav
      className="SecondaryNav navbar is-fixed-top"
      role="navigation"
      aria-label="main navigation"
    >
      <div className="navbar-brand">
        <button
          type="button"
          className="navbar-burger"
          aria-label="menu"
          aria-expanded="false"
          data-target="navbarBasicExample"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </button>
      </div>

      <div id="navbarBasicExample" className="navbar-menu">
        <Link
          activeClassName="is-active"
          className="navbar-item"
          to="/services/messaging"
        >
          {sentenceCase(t('messaging'))}
        </Link>
        <Link
          activeClassName="is-active"
          className="navbar-item"
          to="/services/smsapi"
        >
          {upperCase(t('sms api'))}
        </Link>
        <Link
          activeClassName="is-active"
          className="navbar-item"
          to="/services/hlr"
        >
          {t('HLR lookup')}
        </Link>
        <Link
          activeClassName="is-active"
          className="navbar-item"
          to="/services/campaign-solutions"
        >
          {sentenceCase(t('campaign solutions'))}
        </Link>
        <Link
          activeClassName="is-active"
          className="navbar-item"
          to="/services/webcare"
        >
          {sentenceCase(t('webcare'))}
        </Link>
        <Link
          activeClassName="is-active"
          className="navbar-item"
          to="/services/gateway"
        >
          {sentenceCase(t('gateway'))}
        </Link>
        <Link
          activeClassName="is-active"
          className="navbar-item"
          to="/services/authentication"
        >
          {sentenceCase(t('authentication'))}
        </Link>
        <Link
          activeClassName="is-active"
          className="navbar-item"
          to="/services/smspin"
        >
          {upperCase(t('sms pin'))}
        </Link>
        <Link
          activeClassName="is-active"
          className="navbar-item"
          to="/services/personal-messages"
        >
          {sentenceCase(t('personal messages'))}
        </Link>
      </div>
    </nav>
  );
};

export default SecondaryNav;
