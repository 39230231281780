import React, { ReactElement } from 'react';
import './Developers';
import { CopyBlock, atomOneLight } from 'react-code-blocks';
import codeblocks from './codeblocks';

const DevGateway: React.FC = (): ReactElement => {
  return (
    <div className="DevGateway" id="dev-gateway">
      <h2>Gateway</h2>
      <p>
        Thank you for choosing Direct Messenger for your Gateway. Our SMS
        Gateway uses SMPP protocol.
      </p>
      <h3>Configuring SMS gateway to connect to opensmppbox</h3>
      <p>
        Configuring your SMS gateway to connect to opensmppbox is similar to
        configuring it for any other connection. You have to specify the
        host(URL), port, username and password of the opensmppbox (provided by
        us).
      </p>
      <h5>Example</h5>
      <p>
        Example of configuring kannel SMS gateway (different SMS gateway
        providers can have different configuration formatting!)
      </p>
      <div className="code-block-wrapper">
        <CopyBlock
          text={codeblocks['DevGatewayExample1']}
          showLineNumbers={true}
          theme={atomOneLight}
          wrapLines={true}
          codeBlock
        />
      </div>
      <p>
        After your SMS gateway has been configured sending a message is also
        similar to any other kind of connection.
      </p>
      <p>
        For example here is how you would send a message on your server (where
        SMS gateway is configured like the previous example)
      </p>
      <p>
        {/* <code>
          curl
          "http://localhost:13013/cgi-bin/sendsmssmsc=opensmppbox1&username=simple&password=simple123&from=100&to=2121&text=hello"
        </code> */}
        <div className="code-block-wrapper">
          <CopyBlock
            text={
              'curl "http://localhost:13013/cgi-bin/sendsmssmsc=opensmppbox1&username=simple&password=simple123&from=100&to=2121&text=hello"'
            }
            showLineNumbers={true}
            theme={atomOneLight}
            wrapLines={true}
            codeBlock
          />
        </div>
      </p>
      <p>
        NB! Notice that the username and password are not the opensmmpbox
        username and password but the password and usernames of your gateway’s
        sendsms-user. The only thing you need to specify when sending a message
        to our gateway is the smsc (“opensmppbox1”), everything else is in your
        configuration file.
      </p>
      <p>
        You can read a more detailed kannel (if you’re using another SMS gateway
        provider refer to the appropriate documentation instead) User Guide
        here:
      </p>
      <a
        href="https://www.kannel.org/download/1.5.0/userguide-1.5.0/userguide.html"
        target="_blank"
        rel="noreferrer"
      >
        https://www.kannel.org/download/1.5.0/userguide-1.5.0/userguide.html
      </a>
    </div>
  );
};

export default DevGateway;
