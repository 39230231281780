import React, { ReactElement } from 'react';
import './PrivacyPolicy.scss';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { sentenceCase } from 'converting-case';

// Partial imports
import Et from './Et';
import En from './En';

const PrivacyPolicy: React.FC = (): ReactElement => {
  const { t } = useTranslation();

  const getLanguage = () => i18n.language || window.localStorage.i18nextLng;
  let PolicySet = Et;
  switch (getLanguage()) {
    case 'et':
      PolicySet = Et;
      break;
    case 'en':
      PolicySet = En;
      break;
    default:
      PolicySet = Et;
      break;
  }
  return (
    <div className="PrivacyPolicy container">
      <h2>{sentenceCase(t('privacy policy'))}</h2>
      <PolicySet />
    </div>
  );
};

export default PrivacyPolicy;
