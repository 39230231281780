import React, { ReactElement } from 'react';
import './CampaignSolutions.scss';
import SecondaryNav from '../../../components/SecondaryNav/SecondaryNav';
import CampaignExampleImg1 from '../../../assets/images/services/campaign_template_1.png';
import CampaignExampleImg2 from '../../../assets/images/services/campaign_template_2.png';
import CampaignExampleImg3 from '../../../assets/images/services/campaign_template_3.png';
import { useTranslation } from 'react-i18next';
import { sentenceCase, upperCase } from 'converting-case';
import { Link } from 'react-router-dom';

const CampaignSolutions: React.FC = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <div className="CampaignSolutions" id="campaign-solutions">
      <SecondaryNav />

      <div id="campaign-description" className="campaign-description">
        <div className="columns">
          <div className="column content-column">
            <h2>{sentenceCase(t('CampaignSolutions.header'))}</h2>
            <div className="columns campaign-cards">
              <div className="column card">
                <div className="card-header">
                  <p className="card-header-title">
                    {sentenceCase(t('CampaignSolutions.card 1 title'))}
                  </p>
                </div>
                <div className="card-content">
                  <div className="content">
                    {/* <h2>{sentenceCase(t('CampaignSolutions.card 1 title'))}</h2> */}
                    <p>{sentenceCase(t('CampaignSolutions.card 1 desc 1'))}</p>
                    {/* <p>{sentenceCase(t('CampaignSolutions.card 1 desc 2'))}</p> */}
                  </div>
                </div>
              </div>
              {/* <div className="column card">
                <div className="card-header">
                  <p className="card-header-title">
                    {sentenceCase(t('CampaignSolutions.card 2 title'))}
                  </p>
                </div>
                <div className="card-content">
                  <div className="content">
                    <h2>{sentenceCase(t('CampaignSolutions.card 2 title'))}</h2>
                    <p>{sentenceCase(t('CampaignSolutions.card 2 desc 1'))}</p>
                    <p>{sentenceCase(t('CampaignSolutions.card 2 desc 2'))}</p>
                  </div>
                </div>
              </div> */}
            </div>
            <div className="button-container">
              <a
                href="mailto:support@messenger.ee"
                className="button is-outlined"
              >
                {upperCase(t('contact us'))}
              </a>
              <Link to="/our-works" className="button is-outlined">
                {upperCase(t('our works'))}
              </Link>
            </div>
            <div>
              <p className="vat-notice">
                {t('CampaignSolutions.price without vat')}
              </p>
            </div>
          </div>
          <div className="column content-column mobile-padding">
            {/* <p>{sentenceCase(t('CampaignSolutions.example text part 1'))}</p> */}
            <p>{t('CampaignSolutions.example text part 1')}</p>
            <p>{t('CampaignSolutions.example text part 2')}</p>
            <span>
              {sentenceCase(
                t('CampaignSolutions.we provide a complete technical solution')
              )}
              :
            </span>
            <ul>
              <li>{t('CampaignSolutions.example list item 1')}</li>
              <li>{t('CampaignSolutions.example list item 2')}</li>
              <li>{t('CampaignSolutions.example list item 3')}</li>
              <li>{t('CampaignSolutions.example list item 4')}</li>
              <li>{t('CampaignSolutions.example list item 5')}</li>
              <li>{t('CampaignSolutions.example list item 6')}</li>
              <li>{t('CampaignSolutions.example list item 7')}</li>
            </ul>
          </div>
        </div>
      </div>
      <div id="campaign-templates">
        <div className="container">
          <h3>{t('CampaignSolutions.style example title')}</h3>
          <div className="columns">
            <div className="column">
              <a
                href="https://campaign-single.messenger.ee/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={CampaignExampleImg1}
                  alt="Campaign template style 1"
                />
              </a>
            </div>
            <div className="column">
              <a
                href="https://campaign-multi.messenger.ee/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={CampaignExampleImg2}
                  alt="Campaign template style 2"
                />
              </a>
            </div>
            <div className="column">
              <a
                href="https://campaign-compact.messenger.ee/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={CampaignExampleImg3}
                  alt="Campaign template style 3"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignSolutions;
