import React, { ReactElement } from 'react';
import './OurWorks.scss';
import LightgalleryComp from '../../components/LightgalleryComp/LightgalleryComp';
import {
  Cheetos1,
  Fazer1,
  Geisha2,
  Aviko2,
  Cheetos2,
  RannaRootsi1,
  Cheetos3,
  Lays5,
  Salvest1,
  Fazer3,
  Fazer4,
  Salvest2,
  Seesam1,
  Lays7,
  Lays8,
  Kodusai1,
  Geisha3,
  Salvest3,
  Salvest4,
  Fazer5,
  Fazer6,
  Fazer7,
  Tere1,
  Geisha4,
  Fazer8,
  Cheetos4,
  Fazer9,
  Fazer10,
  Lays9,
  Tuttifrutti2
} from './Galleries';

const OurWorks: React.FC = (): ReactElement => {
  return (
    <div className="OurWorks">
      <div className="columns">
        <div className="column">
          <LightgalleryComp propArray={Tuttifrutti2} />
        </div>
        <div className="column">
          <LightgalleryComp propArray={Lays9} />
        </div>
        <div className="column">
          <LightgalleryComp propArray={Fazer10} />
        </div>
        <div className="column">
          <LightgalleryComp propArray={Fazer9} />
        </div>
        <div className="column">
          <LightgalleryComp propArray={Geisha4} />
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <LightgalleryComp propArray={Fazer8} />
        </div>
        <div className="column">
          <LightgalleryComp propArray={Cheetos4} />
        </div>
        <div className="column">
          <LightgalleryComp propArray={Tere1} />
        </div>
        <div className="column">
          <LightgalleryComp propArray={Fazer7} />
        </div>
        <div className="column">
          <LightgalleryComp propArray={Salvest4} />
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <LightgalleryComp propArray={Fazer5} />
        </div>
        <div className="column">
          <LightgalleryComp propArray={Fazer6} />
        </div>
        <div className="column">
          <LightgalleryComp propArray={Salvest3} />
        </div>
        <div className="column">
          <LightgalleryComp propArray={Geisha3} />
        </div>
        <div className="column">
          <LightgalleryComp propArray={Kodusai1} />
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <LightgalleryComp propArray={Lays8} />
        </div>
        <div className="column">
          <LightgalleryComp propArray={Lays7} />
        </div>
        <div className="column">
          <LightgalleryComp propArray={Salvest2} />
        </div>
        <div className="column">
          <LightgalleryComp propArray={Seesam1} />
        </div>
        <div className="column">
          <LightgalleryComp propArray={Fazer4} />
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <LightgalleryComp propArray={Salvest1} />
        </div>
        <div className="column">
          <LightgalleryComp propArray={Lays5} />
        </div>
        <div className="column">
          <LightgalleryComp propArray={Fazer3} />
        </div>
        <div className="column">
          <LightgalleryComp propArray={Cheetos3} />
        </div>
        <div className="column">
          <LightgalleryComp propArray={RannaRootsi1} />
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <LightgalleryComp propArray={Cheetos2} />
        </div>
        <div className="column">
          <LightgalleryComp propArray={Geisha2} />
        </div>
        <div className="column">
          <LightgalleryComp propArray={Aviko2} />
        </div>
        <div className="column">
          <LightgalleryComp propArray={Cheetos1} />
        </div>
        <div className="column">
          <LightgalleryComp propArray={Fazer1} />
        </div>
      </div>
    </div>
  );
};

export default OurWorks;
