import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

const usePageTracking = () => {
  type trackingCodeType = '' | 'G-7N84EXEM7R';

  const location = useLocation();

  let trackingCode: trackingCodeType = 'G-7N84EXEM7R';

  useEffect(() => {
    ReactGA.initialize(trackingCode);
    //ReactGA.pageview(location.pathname + location.search);
  }, [location, trackingCode]);
};

export default usePageTracking;
