import React, { ReactElement } from 'react';
import './Developers';
import { CopyBlock, atomOneLight } from 'react-code-blocks';
import codeblocks from './codeblocks';

const DevTwoFactor: React.FC = (): ReactElement => {
  return (
    <div className="DevTwoFactor" id="dev-2fa-api">
      <h2>Two Factor Authentication API</h2>
      <p>
        Thank you for choosing Direct Messenger for your two factor
        authentication (2FA). This documentation will help you set up the API
        connection and explains different options available to you.
      </p>
      <h3 id="dev-2fa-what-the-api-offers">What the API offers</h3>
      <p>
        This API offers the ability to generate and send out 2FA codes and
        authenticate them. Also allows the user to generate PIN codes.
      </p>
      <div id="dev-2fa-how-to-get-started">
        <h3>How to get started</h3>
        <h4>1. Get an account</h4>
        <p>
          To send messages using our API you first need a messaging account. You
          can create a Smart account on our website here:{' '}
          <a
            href="https://smart.messenger.ee/register"
            target="_blank"
            rel="noreferrer"
          >
            smart.messenger.ee
          </a>
          . To get a Premium account you need to contact us on{' '}
          <a href="mailto:support@messenger.ee">support@messenger.ee</a>
        </p>
        <h4>2. Set up connection using API KEY</h4>
        <p>
          The next step is to setup your connection to our server. We use an API
          KEY to authenticate all our incoming request. If you do not have an
          API key or you have lost it feel free to{' '}
          <a href="mailto:support@messenger.ee">contact us</a>. For Smart
          account the API KEY will be generated on your account right after the
          credit has been added.
        </p>
      </div>
      <div id="dev-2fa-functions">
        <h3>Functions</h3>
        <h4 id="dev-2fa-send-code">Send 2FA code</h4>
        <p>Send a code to the person requesting authentication</p>
        <h4>Setup</h4>
        <p>
          The setup is fairly simple if you are familiar with <code>POST</code>{' '}
          requests.
        </p>
        <p>
          The most basic request to send 2FA code through our API requires the
          following parameters:
        </p>
        <ul>
          <li>
            <code>api_token</code> -{' '}
            <span className="italic">Your API key</span>
          </li>
          <li>
            <code>receiver</code> -{' '}
            <span className="italic">
              Where to send the 2FA code (phone number)
            </span>
          </li>
        </ul>
        <p>
          We will explain those parameters in more detail further down and go
          over some common mistakes/misunderstandings. Also there are optional
          parameters and options we will also cover below.
        </p>
        <p>Here's what a basic request like that might look like:</p>
        <p>
          {/* <code>
            POST https://API_URL/?api_token=YOUR_API_KEY&receiver=372555555555
          </code> */}
          <div className="code-block-wrapper">
            <CopyBlock
              text={
                'POST https://API_URL/?api_token=YOUR_API_KEY&receiver=372555555555'
              }
              showLineNumbers={true}
              theme={atomOneLight}
              wrapLines={true}
              codeBlock
            />
          </div>
        </p>
        <p>
          This request will validate all the fields and on success will store
          them in our system and send out the message with the 2FA code or in
          case the validation failed give you back what caused the validation to
          fail.
        </p>
      </div>
      <div id="dev-2fa-send-code-example">
        <h4>Example</h4>
        <p>
          A basic request using <code>PHP cURL</code>
        </p>
        <div className="code-block-wrapper">
          <CopyBlock
            language="php"
            text={codeblocks['DevTwoFactorExample1']}
            showLineNumbers={true}
            theme={atomOneLight}
            wrapLines={true}
            codeBlock
          />
        </div>
        <p>
          Javascript <code>ajax</code> example
        </p>
        <div className="code-block-wrapper">
          <CopyBlock
            language="javascript"
            text={codeblocks['DevTwoFactorExample2']}
            showLineNumbers={true}
            theme={atomOneLight}
            wrapLines={true}
            codeBlock
          />
        </div>
      </div>
      <div id="dev-2fa-send-code-available-params">
        <h4>Available parameters</h4>
        <table className="table is-bordered">
          <thead>
            <tr>
              <th>Attribute</th>
              <th>Description</th>
              <th>Default</th>
              <th>Type of validation performed if enabled</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <code>api_token</code>
              </td>
              <td>
                Your API key, provided by us, used to authenticate each request.
              </td>
              <td className="has-text-success">Always required</td>
              <td></td>
            </tr>
            <tr>
              <td>
                <code>receiver</code>
              </td>
              <td>The phone number you want to send the 2FA code to.</td>
              <td className="has-text-success">Always required</td>
              <td>Numeric</td>
            </tr>
            <tr>
              <td>
                <code>pin_length</code>
              </td>
              <td>Length of the 2FA code.</td>
              <td className="has-text-danger">Optional. Default length: 6</td>
              <td>Integer. Between 4 and 20</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div id="dev-2fa-send-code-responses">
        <h4>Responses</h4>
        <h5>Success</h5>
        <p>
          After a request, a response is generated. If everything went smoothly
          you will be notified with a success response.
        </p>
        <p>
          The success response contains the sms status, the pin that was sent to
          the client and a pin_id that is used to verify 2FA codes.
        </p>
        <p>
          PS! Keep the pin_id private. This is used to verify pin -s and should
          never be given out to your clients!
        </p>
        <h5>Example</h5>
        <div className="code-block-wrapper">
          <CopyBlock
            language="json"
            text={codeblocks['DevTwoFactorResponse1']}
            showLineNumbers={true}
            theme={atomOneLight}
            wrapLines={true}
            codeBlock
          />
        </div>
        <h5>Failure</h5>
        <p>
          If your request fails we will send you back a response with validation
          errors that you can use to figure out what went wrong.
        </p>
        <h5>Example</h5>
        <p>Some examples of unsuccessful responses</p>
        <p>
          The <code>receiver</code> field was missing
        </p>
        <div className="code-block-wrapper">
          <CopyBlock
            language="json"
            text={codeblocks['DevTwoFactorResponse2']}
            showLineNumbers={true}
            theme={atomOneLight}
            wrapLines={true}
            codeBlock
          />
        </div>
        <p>
          The <code>receiver</code> was an invalid phone number
        </p>
        <div className="code-block-wrapper">
          <CopyBlock
            language="json"
            text={codeblocks['DevTwoFactorResponse3']}
            showLineNumbers={true}
            theme={atomOneLight}
            wrapLines={true}
            codeBlock
          />
        </div>
        <p>
          The <code>pin_length</code> was too long
        </p>
        <div className="code-block-wrapper">
          <CopyBlock
            language="json"
            text={codeblocks['DevTwoFactorResponse4']}
            showLineNumbers={true}
            theme={atomOneLight}
            wrapLines={true}
            codeBlock
          />
        </div>
      </div>
      <div id="dev-2fa-authenticate-users">
        <h3>Authenticate Users</h3>
        <p>The other feature the API offers is to authenticate users.</p>
        <h4>Setup</h4>
        <p>This request makes use of the POST method.</p>
        <p>
          The most basic request to send data through our API requires the
          following parameters:
        </p>
        <ul>
          <li>
            <code>api_token</code> -{' '}
            <span className="italic">Your API key</span>
          </li>
          <li>
            <code>pin</code> -{' '}
            <span className="italic">The 2FA code you are verifying</span>
          </li>
          <li>
            <code>pin_id</code> -{' '}
            <span className="italic">
              The pin_id we sent you back with the initial 2FA request
            </span>
          </li>
        </ul>
        <p>Here's what a request might look like:</p>
        <p>
          {/* <code>
            POST
            https://API_URL/?api_token=YOUR_API_KEY&pin=PIN_TO_AUTH&pin_id=PIN_ID
          </code> */}
          <div className="code-block-wrapper">
            <CopyBlock
              text={
                'POST https://API_URL/?api_token=YOUR_API_KEY&pin=PIN_TO_AUTH&pin_id=PIN_ID'
              }
              showLineNumbers={true}
              theme={atomOneLight}
              wrapLines={true}
              codeBlock
            />
          </div>
        </p>
        <h4 id="dev-2fa-authenticate-users-example">Example</h4>
        <p>Example requesting authentication.</p>
        <h5>Request</h5>
        <p>
          <code>PHP cURL</code> example
        </p>
        <div className="code-block-wrapper">
          <CopyBlock
            language="php"
            text={codeblocks['DevTwoFactorExample3']}
            showLineNumbers={true}
            theme={atomOneLight}
            wrapLines={true}
            codeBlock
          />
        </div>
        <p>
          Javascript <code>ajax</code> example
        </p>
        <div className="code-block-wrapper">
          <CopyBlock
            language="javascript"
            text={codeblocks['DevTwoFactorExample4']}
            showLineNumbers={true}
            theme={atomOneLight}
            wrapLines={true}
            codeBlock
          />
        </div>
      </div>
      <div id="dev-2fa-authenticate-users-available-params">
        <h4>Available parameters</h4>
        <table className="table is-bordered">
          <thead>
            <tr>
              <th>Attribute</th>
              <th>Description</th>
              <th>Default</th>
              <th>Type of validation performed</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <code>api_token</code>
              </td>
              <td>
                Your API key, provided by us, used to authenticate each request.
              </td>
              <td className="has-text-success">Always required</td>
              <td></td>
            </tr>
            <tr>
              <td>
                <code>pin</code>
              </td>
              <td>2FA code that you are verifying.</td>
              <td className="has-text-success">Always required</td>
              <td>String. Between 4 and 20</td>
            </tr>
            <tr>
              <td>
                <code>pin_id</code>
              </td>
              <td>
                Corresponding PIN ID we sent back with the initial request.{' '}
              </td>
              <td className="has-text-success">Always required</td>
              <td>String. Size 20</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div id="dev-2fa-authenticate-users-responses">
        <h4>Responses</h4>
        <h5>Success</h5>
        <p>
          The success response contains the auth_status status, the pin that was
          verified and pin_id that was used to verify the 2FA code.
        </p>
        <p>
          <strong>PS!</strong> Keep the pin_id private.
        </p>
        <h5>Example</h5>
        <div className="code-block-wrapper">
          <CopyBlock
            language="json"
            text={codeblocks['DevTwoFactorResponse5']}
            showLineNumbers={true}
            theme={atomOneLight}
            wrapLines={true}
            codeBlock
          />
        </div>
        <h5>Failure</h5>
        <p>
          If your request fails we will send you back a response with validation
          errors that you can use to determine what went wrong.
        </p>
        <h5>Example</h5>
        <p>Some examples of unsuccessful responses</p>
        <p>
          The <code>pin</code> has already been authenticated
        </p>
        <div className="code-block-wrapper">
          <CopyBlock
            language="json"
            text={codeblocks['DevTwoFactorResponse6']}
            showLineNumbers={true}
            theme={atomOneLight}
            wrapLines={true}
            codeBlock
          />
        </div>
        <p>
          The <code>pin</code> provided is invalid
        </p>
        <div className="code-block-wrapper">
          <CopyBlock
            language="json"
            text={codeblocks['DevTwoFactorResponse7']}
            showLineNumbers={true}
            theme={atomOneLight}
            wrapLines={true}
            codeBlock
          />
        </div>
        <p>
          The <code>pin</code> and <code>pin_id</code> don't match
        </p>
        <div className="code-block-wrapper">
          <CopyBlock
            language="json"
            text={codeblocks['DevTwoFactorResponse8']}
            showLineNumbers={true}
            theme={atomOneLight}
            wrapLines={true}
            codeBlock
          />
        </div>
        <p>
          The <code>pin</code> has expired (expires after 5 minutes)
        </p>
        <div className="code-block-wrapper">
          <CopyBlock
            language="json"
            text={codeblocks['DevTwoFactorResponse9']}
            showLineNumbers={true}
            theme={atomOneLight}
            wrapLines={true}
            codeBlock
          />
        </div>
      </div>
    </div>
  );
};

export default DevTwoFactor;
