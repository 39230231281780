import React, { ReactElement, useEffect, useState } from 'react';
import './PersonalSteps.scss';
import { faFileAlt } from '@fortawesome/free-regular-svg-icons';
import { faPen, faSms } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { Parallax } from 'react-scroll-parallax';
import { upperCase } from 'converting-case';
import { NavLink as Link } from 'react-router-dom';

const PersonalSteps: React.FC = (): ReactElement => {
  const { t } = useTranslation();

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
  });

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  const disableParallax = (windowWidth: number) => {
    return windowWidth <= 992;
  };

  return (
    <div className="PersonalSteps">
      <h2>{t('PersonalSteps.header')}</h2>
      <div className="columns">
        <div className="column step-1">
          <Parallax
            className="custom-class"
            x={[-100, 50]}
            tagOuter="figure"
            disabled={disableParallax(width)}
          >
            <div className="step">
              <div className="step-header step-1">
                <FontAwesomeIcon icon={faFileAlt} />
              </div>
              <div className="step-body step-1">
                <span className="heading">
                  {t('PersonalSteps.step-1 header')}
                </span>
                <span>{t('PersonalSteps.step-1 content')}</span>
              </div>
            </div>
          </Parallax>
        </div>
        <div className="column step-2">
          <div className="step">
            <div className="step-header step-2">
              <FontAwesomeIcon icon={faPen} />
            </div>
            <div className="step-body step-2">
              <span className="heading">
                {t('PersonalSteps.step-2 header')}
              </span>
              <span>{t('PersonalSteps.step-2 content')}</span>
            </div>
          </div>
        </div>
        <div className="column step-3">
          <Parallax
            className="custom-class"
            x={[100, -50]}
            tagOuter="figure"
            disabled={disableParallax(width)}
          >
            <div className="step">
              <div className="step-header step-3">
                <FontAwesomeIcon icon={faSms} />
              </div>
              <div className="step-body step-3">
                <span className="heading">
                  {t('PersonalSteps.step-3 header')}
                </span>
                <span>{t('PersonalSteps.step-3 content')}</span>
              </div>
            </div>
          </Parallax>
        </div>
      </div>
      <Link
        className="button is-primary is-outlined"
        to="/services/personal-messages"
      >
        {upperCase(t('read more'))}
      </Link>
    </div>
  );
};

export default PersonalSteps;
