const codeblocks = {
  DevSmsApiExample1: `// Get cURL resource
$curl = curl_init();
// Set some options - we are passing in a useragent too here
curl_setopt_array($curl, [
    CURLOPT_HTTPHEADER => [
        'Accept:application/json',
    ],
    CURLOPT_RETURNTRANSFER => 1,
    CURLOPT_URL => 'API_URL',
    CURLOPT_POST => 1,
    CURLOPT_POSTFIELDS => [
        api_token => 'YOUR_API_KEY',
        smstext => 'Message you want to send',
        receiver => '37255555555',
        sender => 'Bar'
    ]
]);
// Send the request & save response to $resp
$resp = curl_exec($curl);
// Close request to clear up some resources
curl_close($curl);
`,
  DevSmsApiResponse1: `{
    "status":"Success",
    "description":"Your message was succesfully added to the queue"
}
`,
  DevSmsApiResponse2: `{
    "status":"Success",
    "description":"Your message was succesfully added to the queue",
    "dlr_id":"6d775630-54b0-4da2-b0b2-8f22f2d91e56"
}
`,
  DevSmsApiResponse3: `{
  "status":"Success",
  "description":"Your message was succesfully added to the queue",
  "warning":"Balance low, please contact us!"
}
`,
  DevSmsApiResponse4: `{
    {
        "message": "The given data was invalid.",
        "errors": {
            "dlruri": [
                "The dlruri format is invalid."
            ]
        }
    }
}
`,
  DevSmsApiResponse5: `{
    "message": "Problem with validating numbers",
    "error": "Invalid number"
}
`,
  DevSmsApiExample2: `// Get cURL resource
$curl = curl_init();
// Set some options - we are passing in a useragent too here
curl_setopt_array($curl, [
    CURLOPT_HTTPHEADER => [
        'Accept:application/json',
    ],
    CURLOPT_RETURNTRANSFER => 1,
    CURLOPT_URL => 'API_URL',
    CURLOPT_POST => 1,
    CURLOPT_POSTFIELDS => [
        api_token => 'YOUR_API_KEY',
        smstext => 'Message you want to send',
        receiver => '3725555555',
        sender => 'Bar',
        conversion => 2,
        deliverytime => '2019-12-30 15:30:00',
        dlruri => 'https://www.your-dlr-url.com',
        dlrid => 158,
    ]
]);
// Send the request & save response to $resp
$resp = curl_exec($curl);
// Close request to clear up some resources
curl_close($curl);
`,
  DevSmsApiExample3: `$curl = curl_init();

curl_setopt_array($curl, [
    CURLOPT_HTTPHEADER => [
        'Content-Type: application/x-www-form-urlencoded',
        'Accept: application/json'
    ],
    CURLOPT_RETURNTRANSFER => 1,
    CURLOPT_URL => 'API_URL',
    CURLOPT_POST => 1,
    CURLOPT_POSTFIELDS =>
        'api_token=YOUR_API_KEY&smstext=YOUR_MESSAGE&receiver=37255555555&sender=Bar&conversion=2&deliverytime=2019-12-30%2015:30:00&dlruri=https://www.your-dlr-url.com&dlrid=158',
]);

$resp = curl_exec($curl);

curl_close ($curl);
`,
  DevSmsApiExample4: `$curl = curl_init();

curl_setopt_array($curl, [
    CURLOPT_RETURNTRANSFER => 1,
    CURLOPT_HTTPHEADER => [
        'Accept: application/json',
    ],
    //Similar to x-www-form-urlencoded but we pass the parameters inside the URL itself
    CURLOPT_URL => 'https://API_URL/?api_token=YOUR_API_KEY&smstext=YOUR_MESSAGE&receiver=37255555555&sender=Bar&conversion=2&deliverytime=2019-12-30%2015:30:00&dlruri=https://www.your-dlr-url.com&dlrid=158',
]);

$resp = curl_exec($curl);

curl_close ($curl);
`,
  DevSmsApiResponse6: `{
    "message": "Success",
    "description": "Your message was succesfully added to the queue",
    "dlr_id": "158"
}
`,
  DevTwoFactorExample1: `// Get cURL resource
$curl = curl_init();
// Set some options - we are passing in a useragent too here
curl_setopt_array($curl, [
    CURLOPT_HTTPHEADER => [
        'Accept:application/json',
    ],
    CURLOPT_RETURNTRANSFER => 1,
    CURLOPT_URL => 'API_URL',
    CURLOPT_POST => 1,
    CURLOPT_POSTFIELDS => [
        api_token => 'YOUR_API_KEY',
        receiver => '372555555555',
    ]
]);
// Send the request & save response to $resp
$resp = curl_exec($curl);
// Close request to clear up some resources
curl_close($curl);
`,
  DevTwoFactorExample2: `var form = new FormData();
form.append("api_token", "YOUR_API_KEY");
form.append("receiver", "372555555555");

var settings = {
"url": "API_URL",
"method": "POST",
"timeout": 0,
"headers": {
    "Accept": "application/json",
    "Content-Type": "application/x-www-form-urlencoded"
},
"processData": false,
"mimeType": "multipart/form-data",
"contentType": false,
"data": form
};

$.ajax(settings).done(function (response) {
    console.log(response);
});
`,
  DevTwoFactorResponse1: `{
  "message": "Success",
  "response": {
      "pin": "MP3KMJ",
      "pin_id": "BEN4KJIK62GNZDVFJCMG",
      "sms": "sent"
  }
}
`,
  DevTwoFactorResponse2: `{
  "message": "The given data was invalid.",
  "errors": {
      "receiver": [
          "The receiver field is required."
      ]
  }
}
`,
  DevTwoFactorResponse3: `{
  "message": "The given data was invalid.",
  "errors": {
      "receiver": [
          "Invalid number"
      ]
  }
}
`,
  DevTwoFactorResponse4: `{
  "message": "The given data was invalid.",
  "errors": {
      "pin_length": [
          "The pin length must be between 4 and 20."
      ]
  }
}
`,
  DevTwoFactorExample3: `// Get cURL resource
$curl = curl_init();
// Set some options - we are passing in a useragent too here
curl_setopt_array($curl, [
    CURLOPT_HTTPHEADER => [
        'Accept:application/json',
    ],
    CURLOPT_RETURNTRANSFER => 1,
    CURLOPT_URL => 'API_URL',
    CURLOPT_POST => 1,
    CURLOPT_POSTFIELDS => [
        api_token => 'YOUR_API_KEY',
        pin => 'PIN_TO_AUTH',
        pin_id => 'PIN_ID',
    ]
]);
// Send the request & save response to $resp
$resp = curl_exec($curl);
// Close request to clear up some resources
curl_close($curl);
`,
  DevTwoFactorExample4: `var form = new FormData();
form.append("api_token", "YOUR_API_KEY");
form.append("pin", "PIN_TO_AUTH");
form.append("pin_id", "PIN_ID");

var settings = {
"url": "API_URL",
"method": "POST",
"timeout": 0,
"headers": {
    "Accept": "application/json",
    "Content-Type": "application/x-www-form-urlencoded"
},
"processData": false,
"mimeType": "multipart/form-data",
"contentType": false,
"data": form
};

$.ajax(settings).done(function (response) {
    console.log(response);
});
`,
  DevTwoFactorResponse5: `{
  "message": "Success",
  "response": {
      "auth_status": "User has been authenticated",
      "pin": "DXYMZO",
      "pin_id": "XOK4426WH1HLOG1BOUJK"
  }
}
`,
  DevTwoFactorResponse6: `{
  "message": "The given data was invalid.",
  "errors": {
      "auth_status": [
          "The PIN has already been authenticated"
      ]
  }
}
`,
  DevTwoFactorResponse7: `{
  "message": "The given data was invalid.",
  "errors": {
      "pin": [
          "PIN not found"
      ]
  }
}
`,
  DevTwoFactorResponse8: `{
  "message": "The given data was invalid.",
  "errors": {
      "pin_id": [
          "PIN and PIN ID not matching"
      ]
  }
}
`,
  DevTwoFactorResponse9: `{
  "message": "The given data was invalid.",
  "errors": {
      "expire_time": [
          "The PIN given has expired"
      ]
  }
}
`,
  DevGatewayExample1: `group = smsc
smsc = smpp
smsc-id = opensmppbox1
allowed-smsc-id = opensmppbox1
preferred-smsc-id = opensmppbox1
host = URL
port = 1234
receive-port = 1234
transceiver-mode = no
smsc-username = username
smsc-password = password
system-type = mysmsbox
log-file = /var/log/kannel/smsc-server.log
log-level = 0
`,
  DevPersonalExample1: `// Get cURL resource
$curl = curl_init();
// Set some options
curl_setopt_array($curl, [
    CURLOPT_HTTPHEADER => [
        'Accept: application/json',
        'Content-Type: application/x-www-form-urlencoded'
    ],
    CURLOPT_RETURNTRANSFER => 1,
    CURLOPT_URL => 'API_URL',
    CURLOPT_POST => 1,
    CURLOPT_POSTFIELDS => [
        api_token => 'YOUR_API_KEY',
        smstext => 'Foo %var% Bar',
        var => 'variable'
    ]
]);
// Send the request & save response to $resp
$resp = curl_exec($curl);
// Close request to clear up some resources
curl_close($curl);
`,
  DevPersonalResponse1: `{
  "message": "Success",
  "id": "8f793eda-78c7-47f9-ad00-1b4fdc8e9ae6",
  "original_text": "Foo %my_var% Bar %second_var%",
  "formatted_text": "Foo variable Bar another variable",
  "receiver": "37255555555555"
}
`,
  DevPersonalResponse2: `{
    "message": "The given data was invalid.",
    "errors": {
        "smstext": ["The smstext field is required."]
    }
}
`,
  DevBlacklistGetExample: `// Get cURL resource
$curl = curl_init();
// Set some options - we are passing in a useragent too here
curl_setopt_array($curl, [
    CURLOPT_HTTPHEADER => [
        'Accept:application/json',
        'Authorization: Bearer YOUR_API_KEY',
    ],
    CURLOPT_RETURNTRANSFER => 1,
    CURLOPT_URL => 'API_URL/api/blacklist',
    CURLOPT_CUSTOMREQUEST => 'GET',

]);
// Send the request & save response to $resp
$resp = curl_exec($curl);
// Close request to clear up some resources
curl_close($curl);
`,
  DevBlacklistGetResponse: `{
    "response": "Success",
    "message": "Blacklist",
    "data": [
        {
            "id": 2222,
            "receiver": "37256666666",
            "created_at": "2021-31-12 10:47:14",
            "source": "api"
        },
        {
            "id": 3333,
            "receiver": "37255555555",
            "created_at": "2021-31-12 10:55:28",
            "source": "api"
        }
    ]
}
`,
  DevBlacklistAddExample: `// Get cURL resource
  $curl = curl_init();
  // Set some options - we are passing in a useragent too here
  curl_setopt_array($curl, [
      CURLOPT_HTTPHEADER => [
          'Accept:application/json',
      ],
      CURLOPT_RETURNTRANSFER => 1,
      CURLOPT_URL => 'API_URL/api/blacklist/create',
      CURLOPT_POST => 1,
      CURLOPT_POSTFIELDS => [
          api_token => 'YOUR_API_KEY',
          receiver => '3725555555',
      ]
  ]);
  // Send the request & save response to $resp
  $resp = curl_exec($curl);
  // Close request to clear up some resources
  curl_close($curl);
`,
  DevBlacklistAddResponse: `{
    "response": "Success",
    "message": "Number (37255555555) added to your blacklist"
}
`,
  DevBlacklistRemoveExample: `// Get cURL resource
  $curl = curl_init();
  // Set some options - we are passing in a useragent too here
  curl_setopt_array($curl, [
      CURLOPT_HTTPHEADER => [
          'Accept:application/json',
      ],
      CURLOPT_RETURNTRANSFER => 1,
      CURLOPT_URL => 'API_URL/api/blacklist/delete/2222',
      CURLOPT_POST => 1,
      CURLOPT_POSTFIELDS => [
          api_token => 'YOUR_API_KEY',
      ]
  ]);
  // Send the request & save response to $resp
  $resp = curl_exec($curl);
  // Close request to clear up some resources
  curl_close($curl);
`,
  DevBlacklistRemoveResponse: `{
    "response": "Success",
    "message": "Number with id 2222 removed from blacklist"
}
`,
};

export default codeblocks;
