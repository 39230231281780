import React, { ReactElement } from 'react';
import './HLR.scss';
import SubPageHero from '../../../components/SubPageHero/SubPageHero';
import HeroImage from '../../../assets/images/hero/tiles.jpg';
import { useTranslation } from 'react-i18next';
import { upperCase } from 'converting-case';
import SecondaryNav from '../../../components/SecondaryNav/SecondaryNav';

const HLR: React.FC = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <div className="HLR">
      <SecondaryNav />
      <SubPageHero image={HeroImage}>
        <h2>{t('HLR lookup')}</h2>
        <p>{t('HLR page desc')}</p>
        <a className="button is-outlined" href="mailto:support@messenger.ee">
          {upperCase(t('contact us'))}
        </a>
      </SubPageHero>
    </div>
  );
};

export default HLR;
