import React, { ReactElement, useEffect, useState } from 'react';
import './Welcome.scss';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { sentenceCase } from 'converting-case';
import axios from 'axios';
import { faComment } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IphoneEt from '../../assets/images/welcome/iphone-4OJO.png';
import IphoneEn from '../../assets/images/welcome/iphone-4OJO.png';

const Welcome: React.FC = (): ReactElement => {
  const { t } = useTranslation();

  const getLanguage = () => i18n.language || window.localStorage.i18nextLng;

  type ImageType = typeof IphoneEt | typeof IphoneEn;

  const [Image, setImage] = useState<ImageType>(IphoneEt);
  useEffect(() => {
    setTimeout(function () {
      switch (getLanguage()) {
        case 'en':
          setImage(IphoneEn);
          break;
        case 'et':
          setImage(IphoneEt);
          break;
        default:
          setImage(IphoneEn);
          break;
      }
    }, 100);
  }, []);

  return (
    <div className="Welcome container">
      <div className="promo-content">
        <div className="columns">
          <div className="column is-two-thirds">
            <h2>{t('promo.first.header')}</h2>
            <p>{t('promo.first.1')}</p>
            <p>{t('promo.first.2')}</p>
            <p>{t('promo.first.3')}</p>
            <div className="columns button-columns">
              <div className="columns buttons">
                <div className="column">
                  <a href="/developers" className="button">
                    {t('promo.first.btn1')}
                  </a>
                </div>
                <div className="column">
                  <a href="#footer" className="button">
                    {t('promo.first.btn2')}
                  </a>
                </div>
                <div className="column">
                  <a
                    href="/pricelist"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="button"
                  >
                    {t('promo.first.btn3')}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="column iphone-img">
            <img src={Image} alt="" />{' '}
          </div>
        </div>
        <div className="divider"></div>

        <h3 className="boxes-header">{t('promo.second.header')}</h3>

        <div className="columns icon-boxes">
          <div className="column">
            <div className="box">
              <span className="icon">
                <FontAwesomeIcon icon={faComment} />
              </span>
              {t('promo.second.box-1-content')}
            </div>
          </div>
          <div className="column">
            <div className="box">
              <span className="icon">
                <FontAwesomeIcon icon={faComment} />
              </span>
              {t('promo.second.box-2-content')}
            </div>
          </div>
          <div className="column">
            <div className="box">
              <span className="icon">
                <FontAwesomeIcon icon={faComment} />
              </span>
              {t('promo.second.box-3-content')}
            </div>
          </div>
          <div className="column">
            <div className="box">
              <span className="icon">
                <FontAwesomeIcon icon={faComment} />
              </span>
              {t('promo.second.box-4-content')}
            </div>
          </div>
          <div className="column">
            <div className="box">
              <span className="icon">
                <FontAwesomeIcon icon={faComment} />
              </span>
              {t('promo.second.box-5-content')}
            </div>
          </div>
        </div>

        <div className="divider"></div>

        <div className="account-list">
          <p>
            {t('promo.account.head1')}
            <span className="bold">{t('promo.account.head2')}</span>
            {t('promo.account.head3')}
            <span className="bold">{t('promo.account.head4')}</span>
            {t('promo.account.head5')}
          </p>
          <div className="columns">
            <div className="column">
              <ul>
                <li>{t('promo.account.list.1')}</li>
                <li>{t('promo.account.list.2')}</li>
                <li>{t('promo.account.list.3')}</li>
                <li>{t('promo.account.list.4')}</li>
              </ul>
            </div>
            <div className="column">
              <ul>
                <li>{t('promo.account.list.5')}</li>
                <li>{t('promo.account.list.6')}</li>
                <li>{t('promo.account.list.7')}</li>
                <li>{t('promo.account.list.8')}</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="price-example">
          <p>
            {t('promo.price.1')}
            <span className="bold">{t('promo.price.2')}</span>
            {t('promo.price.3')}
            <span className="bold pink">{t('promo.price.4')}</span>
            {t('promo.price.5')}
            <span className="bold">{t('promo.price.6')}</span>
          </p>
          <p>{t('promo.price.vat')}</p>
          <p>
            {t('promo.price.acc1')}
            <a
              href="https://smart.messenger.ee/register"
              target="_blank"
              rel="noreferrer noopener"
            >
              {t('promo.price.acc2')}
            </a>
            {t('promo.price.acc3')}
          </p>
        </div>
        {/* <div id="mail-form">
        <form method="POST" action="/send-mail">
            <div className="field">
                <label className="label">{t('promo.name')}</label>
                <div className="control">
                  <input className="input" name="name" type="text" placeholder="{t('promo.name_placeholder')}">
                </div>
              </div>
              <div className="field">
                <label className="label">{t('promo.message')}</label>
                <div className="control">
                  <textarea name="message" className="textarea" placeholder="{t('promo.message_placeholder')}"></textarea>
                </div>
              </div>
              <div className="field is-grouped">
                <div className="control">
                  <button type="submit" className="button">{t('promo.submit')}</button>
                </div>
              </div>
        </form>
    </div> */}
      </div>
    </div>
  );
};

export default Welcome;
