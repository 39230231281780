import React, { ReactElement } from 'react';
import './Developers';
import { CopyBlock, atomOneLight } from 'react-code-blocks';
import codeblocks from './codeblocks';

const DevPersonal: React.FC = (): ReactElement => {
  return (
    <div className="DevPersonal" id="dev-personal">
      <h2>Personal API</h2>
      <p>Personal API helps you personalize your messages.</p>
      <h3>How to get started</h3>
      <h4>Setup</h4>
      <p>
        The setup is fairly simple if you are familiar with <code>POST</code>{' '}
        requests. The most basic request to personalize your message using our
        API requires the following parameters:
      </p>
      <ul>
        <li>
          <code>api_token</code> - Your API key
        </li>
        <li>
          <code>smstext</code> - Message with variables that you want
          personalize
        </li>
        <li>
          <code>your variable</code> - Whatever you set as your variable(s)
        </li>
      </ul>
      <p>
        We will explain some of those parameters in more detail further down and
        go over some common mistakes/misunderstandings.
      </p>
      <p>
        <strong>PS! Every request should use UTF-8 encoding</strong>{' '}
      </p>
      <p>Here's what a basic request like that might look like:</p>
      <p>
        <div className="code-block-wrapper">
          <CopyBlock
            text={
              'POST https://API_URL/?api_token=YOUR_API_KEY&smstext=Foo%var%Bar&var=variable'
            }
            showLineNumbers={true}
            theme={atomOneLight}
            wrapLines={true}
            codeBlock
          />
        </div>
      </p>
      <h5>Example</h5>
      <div className="code-block-wrapper">
        <CopyBlock
          language="php"
          text={codeblocks['DevPersonalExample1']}
          showLineNumbers={true}
          theme={atomOneLight}
          wrapLines={true}
          codeBlock
        />
      </div>
      <div id="dev-personal-available-parameters">
        <h3>Available parameters</h3>
        <table className="table is-bordered">
          <thead>
            <tr>
              <th>Attribute</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <code>api_token </code>
              </td>
              <td>
                Your API key, provided by us, used to authenticate each request.
              </td>
            </tr>
            <tr>
              <td>
                <code>smstext</code>
              </td>
              <td>
                Text you want to personalize. Variables that you want to change
                are marked with two percent (%) symbols (
                <strong className="italic">%var%</strong>)
              </td>
            </tr>
            <tr>
              <td>
                <code>var</code>
                <span className="italic attr">custom</span>
              </td>
              <td>
                This is the variable you entered in your text. The attribute is
                customizable, meaning you can have any key as your variable (for
                example if your variable is <code>%my_custom_variable%</code>{' '}
                then you would set this attribute as{' '}
                <code>my_custom_variable</code> etc.) You can have as many
                variables as you want.
              </td>
            </tr>
            <tr>
              <td>
                <code>id</code>
                <span className="italic attr">optional</span>
              </td>
              <td>
                You can specify a custom id and this will be returned back to
                you with response. If this parameter is not specified we will
                provide an ID in the UUID format for you.
              </td>
            </tr>
            <tr>
              <td>
                <code>receiver</code>
                <span className="italic attr">optional</span>
              </td>
              <td>
                You can add a receiver (phone number) with your request. It can
                be useful to identify which custom message goes with what phone
                number. If this attribute is not set it will be returned as{' '}
                <code>null</code>
                in the response.
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div id="dev-personal-responses">
        <h3>Responses</h3>
        <h4>Success</h4>
        <p>
          After a request, a response is generated. If everything went smoothly
          you will be notified with a response.
        </p>
        <h5>Example</h5>
        <p>Success response</p>
        <p>
          <strong>Parameters used:</strong>
        </p>
        <table className="table is-bordered">
          <thead>
            <tr>
              <th>parameter</th>
              <th>value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <code>api_token</code>
              </td>
              <td>YOUR_API_KEY</td>
            </tr>
            <tr>
              <td>
                <code>smstext</code>
              </td>
              <td>Foo %my_var% Bar %second_var%</td>
            </tr>
            <tr>
              <td>
                <code>my_var</code>
              </td>
              <td>variable</td>
            </tr>
            <tr>
              <td>
                <code>second_var</code>
              </td>
              <td>another variable</td>
            </tr>
            <tr>
              <td>
                <code>receiver</code>
              </td>
              <td>37255555555555</td>
            </tr>
          </tbody>
        </table>
        <p>
          <strong>Response:</strong>
        </p>
        <div className="code-block-wrapper">
          <CopyBlock
            language="json"
            text={codeblocks['DevPersonalResponse1']}
            showLineNumbers={true}
            theme={atomOneLight}
            wrapLines={true}
            codeBlock
          />
        </div>

        <h4>Failure</h4>
        <p>
          If your request fails we will send you back a response with a
          description that might indicate to where the process failed and what
          needs to be done in order to solve the issue.
        </p>
        <h5>Example</h5>
        <p>
          No <code>smstext</code> was provided
        </p>
        <div className="code-block-wrapper">
          <CopyBlock
            language="json"
            text={codeblocks['DevPersonalResponse2']}
            showLineNumbers={true}
            theme={atomOneLight}
            wrapLines={true}
            codeBlock
          />
        </div>
      </div>
    </div>
  );
};

export default DevPersonal;
