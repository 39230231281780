import React, { ReactElement, useState } from 'react';
import './MainNav.scss';
import { NavLink as Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Logo from '../../assets/images/logo_no_bg_x192.png';
import { sentenceCase, upperCase } from 'converting-case';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faAngleDown } from '@fortawesome/free-solid-svg-icons';

const MainNav: React.FC = (): ReactElement => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const getLanguage = () => i18n.language || window.localStorage.i18nextLng;
  const switchLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
  };

  const [burgerOpen, toggleBurgerOpen] = useState<boolean>(false);
  const [servicesOpen, toggleServicesOpen] = useState<boolean>(false);
  const [loginOpen, toggleLoginOpen] = useState<boolean>(false);

  return (
    <nav
      className="MainNav navbar is-fixed-top"
      role="navigation"
      aria-label="main navigation"
    >
      <div className="navbar-brand">
        <Link
          exact={true}
          activeClassName="is-active"
          className="navbar-item logo is-hidden-tablet"
          to="/"
          onClick={() => {
            toggleBurgerOpen(!burgerOpen);
          }}
        >
          <img src={Logo} alt="Direct Messenger DM logo" />
        </Link>
        <button
          type="button"
          className="navbar-burger"
          aria-label="menu"
          aria-expanded="false"
          data-target="MainNav"
          onClick={() => {
            toggleBurgerOpen(!burgerOpen);
          }}
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </button>
      </div>

      <div
        id="MainNav"
        className={burgerOpen ? 'navbar-menu is-active' : 'navbar-menu'}
      >
        <Link
          exact={true}
          activeClassName="is-active"
          className="navbar-item logo is-hidden-mobile"
          to="/"
          onClick={() => {
            toggleBurgerOpen(!burgerOpen);
          }}
        >
          <img src={Logo} alt="Direct Messenger DM logo" />
        </Link>

        <Link
          exact={true}
          activeClassName="is-active"
          className="navbar-item"
          to="/"
          onClick={() => {
            toggleBurgerOpen(!burgerOpen);
          }}
        >
          {sentenceCase(t('home'))}
        </Link>
        <div className="navbar-item has-dropdown is-hoverable">
          <span
            className="navbar-link"
            onClick={() => {
              toggleServicesOpen(!servicesOpen);
            }}
          >
            {sentenceCase(t('services'))}
          </span>

          <div
            className={
              servicesOpen ? 'navbar-dropdown is-active' : 'navbar-dropdown'
            }
          >
            {location.pathname === '/' ? (
              <a
                href="#messaging"
                className="dropdown-item"
                onClick={() => {
                  toggleBurgerOpen(!burgerOpen);
                }}
              >
                {sentenceCase(t('messaging'))}
              </a>
            ) : (
              <Link
                activeClassName="is-active"
                className="dropdown-item"
                to="/services/messaging"
                onClick={() => {
                  toggleBurgerOpen(!burgerOpen);
                }}
              >
                {sentenceCase(t('messaging'))}
              </Link>
            )}
            <Link
              activeClassName="is-active"
              className="dropdown-item"
              to="/services/smsapi"
              onClick={() => {
                toggleBurgerOpen(!burgerOpen);
              }}
            >
              {upperCase(t('sms api'))}
            </Link>

            {location.pathname === '/' ? (
              <a
                href="#webcare"
                className="dropdown-item"
                onClick={() => {
                  toggleBurgerOpen(!burgerOpen);
                }}
              >
                {sentenceCase(t('webcare'))}
              </a>
            ) : (
              <Link
                activeClassName="is-active"
                className="dropdown-item"
                to="/services/webcare"
                onClick={() => {
                  toggleBurgerOpen(!burgerOpen);
                }}
              >
                {sentenceCase(t('webcare'))}
              </Link>
            )}
            <Link
              activeClassName="is-active"
              className="dropdown-item"
              to="/services/smspin"
              onClick={() => {
                toggleBurgerOpen(!burgerOpen);
              }}
            >
              {upperCase(t('sms pin'))}
            </Link>

            {location.pathname === '/' ? (
              <a
                href="#hlr"
                className="dropdown-item"
                onClick={() => {
                  toggleBurgerOpen(!burgerOpen);
                }}
              >
                {t('HLR lookup')}
              </a>
            ) : (
              <Link
                activeClassName="is-active"
                className="dropdown-item"
                to="/services/hlr"
                onClick={() => {
                  toggleBurgerOpen(!burgerOpen);
                }}
              >
                {t('HLR lookup')}
              </Link>
            )}
            <Link
              activeClassName="is-active"
              className="dropdown-item"
              to="/services/authentication"
              onClick={() => {
                toggleBurgerOpen(!burgerOpen);
              }}
            >
              {t('2-factor authentication')}
            </Link>

            {location.pathname === '/' ? (
              <a
                href="#campaign-solutions"
                className="dropdown-item"
                onClick={() => {
                  toggleBurgerOpen(!burgerOpen);
                }}
              >
                {sentenceCase(t('campaign solutions'))}
              </a>
            ) : (
              <Link
                activeClassName="is-active"
                className="dropdown-item"
                to="/services/campaign-solutions"
                onClick={() => {
                  toggleBurgerOpen(!burgerOpen);
                }}
              >
                {sentenceCase(t('campaign solutions'))}
              </Link>
            )}
            <Link
              activeClassName="is-active"
              className="dropdown-item"
              to="/services/gateway"
              onClick={() => {
                toggleBurgerOpen(!burgerOpen);
              }}
            >
              {sentenceCase(t('gateway'))}
            </Link>
            <Link
              activeClassName="is-active"
              className="dropdown-item"
              to="/services/personal-messages"
              onClick={() => {
                toggleBurgerOpen(!burgerOpen);
              }}
            >
              {sentenceCase(t('personal messages'))}
            </Link>
          </div>
        </div>
        <Link
          activeClassName="is-active"
          className="navbar-item"
          to="/pricelist"
          onClick={() => {
            toggleBurgerOpen(!burgerOpen);
          }}
        >
          {sentenceCase(t('pricelist'))}
        </Link>
        <Link
          activeClassName="is-active"
          className="navbar-item"
          to="/developers"
          onClick={() => {
            toggleBurgerOpen(!burgerOpen);
          }}
        >
          {sentenceCase(t('developers'))}
        </Link>

        <Link
          activeClassName="is-active"
          className="navbar-item"
          to="/clients"
          onClick={() => {
            toggleBurgerOpen(!burgerOpen);
          }}
        >
          {sentenceCase(t('clients'))}
        </Link>
        <a
          href="#footer"
          className="navbar-item"
          onClick={() => {
            toggleBurgerOpen(!burgerOpen);
          }}
        >
          {sentenceCase(t('contact'))}
        </a>
        <div className="navbar-item has-dropdown is-hoverable">
          <span
            className="navbar-link"
            onClick={() => {
              toggleLoginOpen(!loginOpen);
            }}
          >
            {sentenceCase(t('login'))}
          </span>

          <div
            className={
              loginOpen ? 'navbar-dropdown is-active' : 'navbar-dropdown'
            }
          >
            <a
              className="dropdown-item"
              href="https://smart.messenger.ee"
              target="_blank"
              rel="noreferrer"
              onClick={() => {
                toggleBurgerOpen(!burgerOpen);
              }}
            >
              {t('Log in to Smart account')}
            </a>
            <a
              className="dropdown-item"
              href="https://premium.messenger.ee"
              target="_blank"
              rel="noreferrer"
              onClick={() => {
                toggleBurgerOpen(!burgerOpen);
              }}
            >
              {t('Log in to Premium account')}
            </a>{' '}
            <a
              className="dropdown-item"
              href="https://stat.messenger.ee"
              target="_blank"
              rel="noreferrer"
              onClick={() => {
                toggleBurgerOpen(!burgerOpen);
              }}
            >
              {t('Log in to statistics')}
            </a>
          </div>
        </div>

        <div className="navbar-item">
          <div className="buttons">
            <a
              className="button is-outlined"
              href="https://smart.messenger.ee/register"
              target="_blank"
              rel="noreferrer"
              onClick={() => {
                toggleBurgerOpen(!burgerOpen);
              }}
            >
              {sentenceCase(t('sign up'))}
            </a>
          </div>
        </div>
        <button
          type="button"
          className="navbar-item"
          onClick={() =>
            getLanguage() === 'et' ? switchLanguage('en') : switchLanguage('et')
          }
        >
          {getLanguage() === 'et' ? 'EN' : 'ET'}
        </button>
      </div>
    </nav>
  );
};

export default MainNav;
