import React, { ReactElement } from 'react';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import './Map.scss';

const center = {
  lat: 59.417587773116324,
  lng: 24.692645606954798,
};
const Map = (): ReactElement => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyCJpdAwjS0HY6DAFS9WMYpJw9wdAiPrYKQ',
  });

  return isLoaded ? (
    <GoogleMap mapContainerClassName="map-container" center={center} zoom={15}>
      {/* Child components, such as markers, info windows, etc. */}
      <Marker position={center} />
      <></>
    </GoogleMap>
  ) : (
    <></>
  );
};

export default Map;
