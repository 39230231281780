import React, { ReactElement, useEffect, useState } from 'react';
import './PersonalMessages.scss';
import SubPageHero from '../../../components/SubPageHero/SubPageHero';
import HeroImage from '../../../assets/images/hero/dandelion.jpg';
import SecondaryNav from '../../../components/SecondaryNav/SecondaryNav';
import { useTranslation } from 'react-i18next';
import { sentenceCase, upperCase } from 'converting-case';
import i18n from 'i18next';
// import YoutubeEmbed from '../../../components/YoutubeEmbed/YoutubeEmbed';
import { Chart } from 'react-google-charts';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import ee1 from '../../../assets/images/personal/ee_1.png';
import ee2 from '../../../assets/images/personal/ee_2.png';
import ee3 from '../../../assets/images/personal/ee_3.png';
import ee4 from '../../../assets/images/personal/ee_4.png';
import ee5 from '../../../assets/images/personal/ee_5.png';

import en1 from '../../../assets/images/personal/en_1.png';
import en2 from '../../../assets/images/personal/en_2.png';
import en3 from '../../../assets/images/personal/en_3.png';
import en4 from '../../../assets/images/personal/en_4.png';
import en5 from '../../../assets/images/personal/en_5.png';

SwiperCore.use([Navigation, Pagination]);

interface Image {
  Img1: typeof ee1 | typeof en1;
  Img1Key: 'ee1' | 'en1';
  Img2: typeof ee2 | typeof en2;
  Img2Key: 'ee2' | 'en2';
  Img3: typeof ee3 | typeof en3;
  Img3Key: 'ee3' | 'en3';
  Img4: typeof ee4 | typeof en4;
  Img4Key: 'ee4' | 'en4';
  Img5: typeof ee5 | typeof en5;
  Img5Key: 'ee5' | 'en5';
}

const PersonalMessages: React.FC = (): ReactElement => {
  const { t } = useTranslation();

  const getLanguage = () => i18n.language || window.localStorage.i18nextLng;

  const [CurrentActive, setCurrentActive] = useState<number>(0);

  const [Images, setImages] = useState<Image>({
    Img1: en1,
    Img1Key: 'en1',
    Img2: en2,
    Img2Key: 'en2',
    Img3: en3,
    Img3Key: 'en3',
    Img4: en4,
    Img4Key: 'en4',
    Img5: en5,
    Img5Key: 'en5',
  });
  useEffect(() => {
    setTimeout(function () {
      switch (getLanguage()) {
        case 'en':
          setImages({
            Img1: en1,
            Img1Key: 'en1',
            Img2: en2,
            Img2Key: 'en2',
            Img3: en3,
            Img3Key: 'en3',
            Img4: en4,
            Img4Key: 'en4',
            Img5: en5,
            Img5Key: 'en5',
          });
          break;
        case 'et':
          setImages({
            Img1: ee1,
            Img1Key: 'ee1',
            Img2: ee2,
            Img2Key: 'ee2',
            Img3: ee3,
            Img3Key: 'ee3',
            Img4: ee4,
            Img4Key: 'ee4',
            Img5: ee5,
            Img5Key: 'ee5',
          });
          break;
        default:
          setImages({
            Img1: en1,
            Img1Key: 'en1',
            Img2: en2,
            Img2Key: 'en2',
            Img3: en3,
            Img3Key: 'en3',
            Img4: en4,
            Img4Key: 'en4',
            Img5: en5,
            Img5Key: 'en5',
          });
          break;
      }
    }, 100);
  }, []);

  const firstChartPercentage = 98;

  const secondChartPercentage = 51;

  const thirdChartPercentage = 84;

  const firstChartData = [
    ['action', 'amount'],
    ['Opened', firstChartPercentage],
    ['', 100 - firstChartPercentage],
  ];

  const secondChartData = [
    ['action', 'amount'],
    ['Opened', secondChartPercentage],
    ['', 100 - secondChartPercentage],
  ];
  const thirdChartData = [
    ['action', 'amount'],
    ['Opened', thirdChartPercentage],
    ['', 100 - thirdChartPercentage],
  ];

  const chartOptions = {
    pieHole: 0.8,
    is3D: false,
    legend: 'none',
    pieSliceText: 'none',
    // backgroundColor: '#f0f0f0',
    tooltip: {
      trigger: 'none',
    },
    colors: ['#4f1885', '#f0f0f0'],
  };

  return (
    <div className="PersonalMessages">
      <SecondaryNav />
      <SubPageHero image={HeroImage}>
        <h2>{sentenceCase(t('PersonalMessages.header'))}</h2>
        <p className="smaller-padding">
          {t('PersonalMessages.description part 1')}
        </p>
        <p className="smaller-padding">
          {t('PersonalMessages.description part 2')}
        </p>
        <p>{t('PersonalMessages.description part 3')}</p>
        {/* <p className="bold smaller-padding">
          {sentenceCase(t('PersonalMessages.how does it work?'))}
        </p>
        <ul className="smaller-padding">
          <li>{t('PersonalMessages.list item 1')}</li>
          <li>{t('PersonalMessages.list item 2')}</li>
          <li>{t('PersonalMessages.list item 3')}</li>
          <li>{t('PersonalMessages.list item 4')}</li>
          <li>{t('PersonalMessages.list item 5')}</li>
        </ul> */}
        <span>{upperCase(t('PersonalMessages.sms example'))}:</span>
        <div className="example-wrapper">
          <p className="smaller-padding">
            <span className="italic">
              {t('PersonalMessages.example 1.part 1')}
              <span className="highlighted">
                {t('PersonalMessages.example 1.part 2')}
              </span>
              {t('PersonalMessages.example 1.part 3')}
              <span className="highlighted">
                {t('PersonalMessages.example 1.part 4')}
              </span>
              {t('PersonalMessages.example 1.part 5')}
            </span>
          </p>
          <p className="smaller-padding has-text-white">
            {t('PersonalMessages.example 1.part 6')}
          </p>
          {/* <span>{upperCase(t('PersonalMessages.sms example'))}:</span> */}
          <p className="smaller-padding">
            <span className="italic">
              {t('PersonalMessages.example 2.part 1')}
              <span className="highlighted">
                {t('PersonalMessages.example 2.part 2')}
              </span>
              {t('PersonalMessages.example 2.part 3')}
              <span className="highlighted">
                {t('PersonalMessages.example 2.part 4')}
              </span>
              {t('PersonalMessages.example 2.part 5')}
              <span className="highlighted">
                {t('PersonalMessages.example 2.part 6')}
              </span>
              {t('PersonalMessages.example 2.part 7')}
              <span className="highlighted">
                {t('PersonalMessages.example 2.part 8')}
              </span>
            </span>
          </p>
          <p className="smaller-padding has-text-white">
            {t('PersonalMessages.example 2.part 9')}
          </p>
          {/* <span>{upperCase(t('PersonalMessages.sms example'))}:</span> */}
          <p className="smaller-padding">
            <span className="italic">
              {t('PersonalMessages.example 3.part 1')}
              <span className="highlighted">
                {t('PersonalMessages.example 3.part 2')}
              </span>
              {t('PersonalMessages.example 3.part 3')}
              <span className="highlighted">
                {t('PersonalMessages.example 3.part 4')}
              </span>
              {t('PersonalMessages.example 3.part 5')}
              <span className="highlighted">
                {t('PersonalMessages.example 3.part 6')}
              </span>
              {t('PersonalMessages.example 3.part 7')}
              <span className="highlighted">
                {t('PersonalMessages.example 3.part 8')}
              </span>
              {t('PersonalMessages.example 3.part 9')}
            </span>
          </p>
          <p className="has-text-white smaller-padding">
            {t('PersonalMessages.example 3.part 10')}
          </p>
          <p className="has-text-white smaller-padding">
            <span className="bold">
              {t('PersonalMessages.example explanation.part 1')}
            </span>{' '}
            {t('PersonalMessages.example explanation.part 2')}
          </p>
        </div>

        <p>{t('PersonalMessages.personal api available')}</p>
        <a href="mailto:support@messenger.ee" className="button is-outlined">
          {upperCase(t('contact us'))}
        </a>
        <a href="/developers#dev-personal" className="button is-outlined">
          {upperCase(t('documentation'))}
        </a>
        {/* <Link className="button is-outlined" to="/developers#dev-personal">
          {upperCase(t('documentation'))}
        </Link> */}
      </SubPageHero>
      <div id="personal-charts">
        <div className="columns is-centered">
          <div className="column is-narrow">
            <div className="chart-wrapper">
              <Chart
                chartType="PieChart"
                width="100%"
                height="300px"
                data={firstChartData}
                options={chartOptions}
              />
              <div className="center-label">{firstChartPercentage}%</div>
            </div>
            <div className="chart-legend">
              <p>{t('PersonalMessages.charts.chart 1 legend')}</p>
            </div>
          </div>
          <div className="column is-narrow">
            <div className="chart-wrapper">
              <Chart
                chartType="PieChart"
                width="100%"
                height="300px"
                data={secondChartData}
                options={chartOptions}
              />
              <div className="center-label">{secondChartPercentage}%</div>
            </div>
            <div className="chart-legend">
              <p>{t('PersonalMessages.charts.chart 2 legend')}</p>
            </div>
          </div>
          <div className="column is-narrow">
            <div className="chart-wrapper">
              <Chart
                chartType="PieChart"
                width="100%"
                height="300px"
                data={thirdChartData}
                options={chartOptions}
              />
              <div className="center-label">{thirdChartPercentage}%</div>
            </div>
            <div className="chart-legend">
              <p>{t('PersonalMessages.charts.chart 3 legend')}</p>
            </div>
          </div>
        </div>
      </div>
      <div id="personal-example">
        <div className="columns">
          <div className="column image-column">
            {/* <YoutubeEmbed embedId="DWQGFk_ya3I" /> */}
            <Swiper
              // init={alse}
              centeredSlides={true}
              loop={true}
              navigation
              pagination={{ clickable: true }}
              autoplay={{
                delay: 8000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
              }}
              onTransitionEnd={(slider) => {
                setCurrentActive(slider.realIndex);
              }}
              // onSwiper={(swiper) => console.log(swiper)}
            >
              <SwiperSlide>
                <img src={Images.Img1} alt="example 1" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={Images.Img2} alt="example 2" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={Images.Img3} className="centered" alt="example 3" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={Images.Img4} className="centered" alt="example 4" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={Images.Img5} alt="example 5" />
              </SwiperSlide>
            </Swiper>
          </div>
          <div className="column content-column">
            <span className={CurrentActive === 0 ? ' active' : ''}>
              {t('PersonalMessages.example images.example 1 header')}
            </span>
            <p className={CurrentActive === 0 ? ' active' : ''}>
              {t('PersonalMessages.example images.example 1')}
            </p>
            <span className={CurrentActive === 1 ? ' active' : ''}>
              {t('PersonalMessages.example images.example 2 header')}
            </span>
            <p className={CurrentActive === 1 ? ' active' : ''}>
              {t('PersonalMessages.example images.example 2')}
            </p>
            <span className={CurrentActive === 2 ? ' active' : ''}>
              {t('PersonalMessages.example images.example 3 header')}
            </span>
            <p className={CurrentActive === 2 ? ' active' : ''}>
              {t('PersonalMessages.example images.example 3')}
            </p>
            <span className={CurrentActive === 3 ? ' active' : ''}>
              {t('PersonalMessages.example images.example 4 header')}
            </span>
            <p className={CurrentActive === 3 ? ' active' : ''}>
              {t('PersonalMessages.example images.example 4')}
            </p>
            <span className={CurrentActive === 4 ? ' active' : ''}>
              {t('PersonalMessages.example images.example 5 header')}
            </span>
            <p className={CurrentActive === 4 ? ' active' : ''}>
              {t('PersonalMessages.example images.example 5')}
            </p>
            <div className="button-container">
              <a
                className="button is-primary is-outlined"
                target="_blank"
                rel="noreferrer"
                href="https://www.youtube.com/watch?v=DWQGFk_ya3I&feature=emb_logo"
              >
                {upperCase(t('PersonalMessages.watch demo'))}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalMessages;
