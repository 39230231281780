import React, { ReactElement } from 'react';
import './Developers';

const Sidebar: React.FC = (): ReactElement => {
  return (
    <div className="Sidebar">
      <aside className="menu">
        <p className="menu-label">SMS API</p>
        <ul className="menu-list">
          <li>
            <a href="#dev-sms-api">SMS API V2</a>
            <ul>
              <li>
                <a href="#dev-sms-api-how-to-get-started">How to get started</a>
              </li>
              <li>
                <a href="#dev-sms-api-available-parameters">
                  Available parameters
                </a>
              </li>
              <ul>
                <li>
                  <a href="#dlr-example">DLR Interactive Example</a>
                </li>
              </ul>
              <li>
                <a href="#dev-sms-api-responses">Responses</a>
              </li>
              <li>
                <a href="#dev-sms-api-examples">Examples</a>
              </li>
            </ul>
          </li>
        </ul>
        <p className="menu-label">Two Factor Authentication API</p>
        <ul className="menu-list">
          <li>
            <a href="#dev-2fa-api">Two Factor Authentication API</a>
            <ul>
              <li>
                <a href="#dev-2fa-how-to-get-started">How to get started</a>
              </li>
              <li>
                <a href="#dev-2fa-functions">Functions</a>
                <ul>
                  <li>
                    <a href="#dev-2fa-send-code">Send 2FA code</a>
                    <ul>
                      <li>
                        <a href="#dev-2fa-send-code-example">Example</a>
                      </li>
                      <li>
                        <a href="#dev-2fa-send-code-available-params">
                          Available parameters
                        </a>
                      </li>
                      <li>
                        <a href="#dev-2fa-send-code-responses">Responses</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="dev-2fa-authenticate-users">Authenticate users</a>
                    <ul>
                      <li>
                        <a href="#dev-2fa-authenticate-users-example">
                          Example
                        </a>
                      </li>
                      <li>
                        <a href="#dev-2fa-authenticate-users-available-params">
                          Available parameters
                        </a>
                      </li>
                      <li>
                        <a href="#dev-2fa-authenticate-users-responses">
                          Responses
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <p className="menu-label">Personal API</p>
        <ul className="menu-list">
          <li>
            <a href="#dev-personal">Personal API</a>
            <ul>
              <li>
                <a href="#dev-personal-available-parameters">
                  Available parameters
                </a>
              </li>
            </ul>
          </li>
        </ul>
        <p className="menu-label">Gateway</p>
        <ul className="menu-list">
          <li>
            <a href="#dev-gateway">Gateway</a>
          </li>
        </ul>
      </aside>
    </div>
  );
};

export default Sidebar;
