import React, { FormEvent, ReactElement, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { sentenceCase } from 'converting-case';
import './Footer.scss';
import Map from '../Map/Map';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { setTimeout } from 'timers';
import { Link } from 'react-router-dom';

type ContactProps = {
  name?: string;
  occupation?: string;
  phone?: string;
  email?: string;
};

const Contact = ({
  name,
  occupation,
  phone,
  email,
}: ContactProps): ReactElement => {
  return (
    <div className="contact-wrapper">
      {name && <span>{name}</span>}
      {occupation && <span>{occupation}</span>}
      {phone && (
        <a className="phone" href={'tel:' + phone?.replace(/\s/g, '')}>
          {phone}
        </a>
      )}
      {email && (
        <a className="email" href={'mailto:' + email}>
          {email?.replace('@', '[at]')}
        </a>
      )}
    </div>
  );
};

const Footer: React.FC = (): ReactElement => {
  const [mailSent, toggleMailSent] = useState<boolean>(false);

  const sendMail = (event: FormEvent) => {
    event.preventDefault();
    let formData = new FormData(event.target as HTMLFormElement);
    formData.forEach((entries) => entries);

    axios
      .post('/api/mail', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(function (response) {
        // handle success
        if (response.status === 200) {
          (event.target as HTMLFormElement).reset();
          toggleMailSent(true);
          setTimeout(() => {
            toggleMailSent(false);
          }, 5000);
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };

  const { t } = useTranslation();

  return (
    <div className="Footer" id="footer">
      <div className="columns container is-widescreen">
        <div className="column left-column is-one-third">
          <div className="description">
            <h4>DIRECT MESSENGER OÜ</h4>
            <p>{t('footer desc')}</p>
            <Link
              to="/privacy-policy"
              className="button is-primary is-outlined"
            >
              {sentenceCase(t('privacy policy'))}
            </Link>
          </div>
          <h5>{t('Our office is located')}</h5>
          <Map />
        </div>
        <div className="column center-column is-one-third">
          <div>
            <h4>{sentenceCase(t('contact'))}</h4>
            <div className="contact-wrapper">
              {/* <span>{sentenceCase(t('address'))}</span> */}
              <span>Mustamäe tee 44/1</span>
              <span>Tallinn 10621, {sentenceCase(t('estonia'))}</span>
              {/* <span>{sentenceCase(t('estonia'))}</span> */}
            </div>
            <Contact phone="+372 5556 2773" email="support@messenger.ee" />

            <Contact
              name="Andres Kaljo"
              phone="+372 5047 337"
              email="andres@messenger.ee"
            />
          </div>
        </div>
        <div className="column right-column is-one-third">
          {/* <div className="email-success">Email success</div> */}
          <form onSubmit={sendMail}>
            <div className="field">
              {/* <label className="label">{sentenceCase(t('name'))}</label> */}
              <div className="control">
                <input
                  className="input"
                  type="text"
                  placeholder={sentenceCase(t('name'))}
                  name="name"
                />
              </div>
            </div>
            <div className="field">
              {/* <label className="label">{sentenceCase(t('email'))}</label> */}
              <div className="control">
                <input
                  className="input"
                  type="email"
                  placeholder={sentenceCase(t('email'))}
                  name="email"
                />
              </div>
            </div>
            <div className="field">
              {/* <label className="label">{sentenceCase(t('subject'))}</label> */}
              <div className="control">
                <input
                  className="input"
                  type="text"
                  placeholder={sentenceCase(t('subject'))}
                  name="subject"
                />
              </div>
            </div>
            <div className="field">
              {/* <label className="label">{sentenceCase(t('message'))}</label> */}
              <div className="control">
                <textarea
                  className="textarea"
                  placeholder={sentenceCase(t('message'))}
                  name="message"
                ></textarea>
              </div>
            </div>
            <div className="field">
              <div className="control">
                <button
                  className={
                    'button is-outlined is-fullwidth' +
                    (mailSent ? ' email-sent-btn' : '')
                  }
                  disabled={mailSent ? true : false}
                >
                  {mailSent ? (
                    <>
                      <FontAwesomeIcon icon={faCheck} className="mr-1" />{' '}
                      {sentenceCase(t('mail sent'))}
                    </>
                  ) : (
                    sentenceCase(t('submit'))
                  )}
                </button>
              </div>
            </div>
          </form>
          <div className="socials">
            <a
              href="https://www.facebook.com/DM.messenger.ee/"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon icon={faFacebook} />
            </a>
            <a
              href="https://www.linkedin.com/company/direct-messenger-o%C3%BC/"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon icon={faLinkedin} />
            </a>
          </div>
          <div className="vat-wrapper">
            <span>{t('vat number')}: EE101392316</span>
            <span>{sentenceCase(t('registry code'))}: 11981389</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
