import React, { ReactElement } from 'react';
import './LightgalleryComp.scss';
import LightGallery from 'lightgallery/react';
import 'lightgallery/scss/lightgallery.scss';
import 'lightgallery/scss/lg-zoom.scss';
import 'lightgallery/scss/lg-thumbnail.scss';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';

interface Props {
  propArray: { thumb: string; image: string; caption?: string }[];
}

const LightgalleryComp: React.FC<Props> = ({ propArray }): ReactElement => {
  const onInit = () => {
    console.log('lightGallery has been initialized');
  };
  return (
    <div className="LightgalleryComp">
      <LightGallery onInit={onInit} speed={500} plugins={[lgThumbnail, lgZoom]}>
        {propArray.map((item, index) => (
          <a href={item.image} className={index === 0 ? '' : 'hidden'}>
            <img alt={item.caption ?? 'Default caption'} src={item.thumb} />
          </a>
        ))}
      </LightGallery>
    </div>
  );
};

export default LightgalleryComp;
