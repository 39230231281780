import React, { ReactElement, useEffect, useState } from 'react';
import './Pricelist.scss';
import { useTranslation } from 'react-i18next';
import { sentenceCase } from 'converting-case';
import axios from 'axios';

interface PriceObject {
  country: string;
  price: number;
  price_vat: number;
}

const Pricelist: React.FC = (): ReactElement => {
  const { t } = useTranslation();

  const [prices, setPrices] = useState<PriceObject[]>();

  const getPricelist = async () => {
    axios
      .get('/api/pricelist')
      .then((response) => {
        setPrices(response.data.priceList);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getPricelist();
  }, []);

  return (
    <div className="Pricelist container">
      <h2>{sentenceCase(t('pricelist'))}</h2>
      <table className="table is-striped is-hoverable">
        <thead>
          <tr>
            <th>{sentenceCase(t('country'))}</th>
            <th>{sentenceCase(t('price'))} (EUR)</th>
            <th>{t('price_vat')} (EUR)</th>
          </tr>
        </thead>
        <tbody>
          {prices?.map((item, index) => {
            return (
              <tr key={index}>
                <td>{item.country}</td>
                <td>{item.price}</td>
                <td>{item.price_vat}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Pricelist;
