import React, { ReactElement } from 'react';
import './App.scss';
import { Switch, Route } from 'react-router-dom';
import MessengerCustomerChat from 'react-messenger-customer-chat';

// View imports
import Home from './views/Home/Home';
import SmsApi from './views/Services/SmsApi/SmsApi';
import Messaging from './views/Services/Messaging/Messaging';
import HLR from './views/Services/HLR/HLR';
import CampaignSolutions from './views/Services/CampaignSolutions/CampaignSolutions';
import Gateway from './views/Services/Gateway/Gateway';
import PersonalMessages from './views/Services/PersonalMessages/PersonalMessages';
import Webcare from './views/Services/Webcare/Webcare';
import SmsPin from './views/Services/SmsPin/SmsPin';
import Authentication from './views/Services/Authentication/Authentication';
import Clients from './views/Clients/Clients';
import OurWorks from './views/OurWorks/OurWorks';
import Developers from './views/Developers/Developers';
import PrivacyPolicy from './views/PrivacyPolicy/PrivacyPolicy';
import Pricelist from './views/Pricelist/Pricelist';
import Welcome from './views/Welcome/Welcome';

// Component imports
import MainNav from './components/MainNav/MainNav';
import Footer from './components/Footer/Footer';
import usePageTracking from './components/PageTracking/usePageTracking';

const App: React.FC = (): ReactElement => {
  usePageTracking();
  return (
    <div className="App">
      <MessengerCustomerChat
        pageId="654668651252177"
        appId=""
        themeColor="#501885"
        loggedInGreeting="Hi! How can we help you? Tere! Kuidas saame aidata?"
        loggedOutGreeting="Hi! How can we help you? Tere! Kuidas saame aidata?"
      />

      <MainNav />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/services/smsapi" component={SmsApi} />
        <Route path="/services/messaging" component={Messaging} />
        <Route path="/services/hlr" component={HLR} />
        <Route
          path="/services/campaign-solutions"
          component={CampaignSolutions}
        />
        <Route path="/services/gateway" component={Gateway} />
        <Route
          path="/services/personal-messages"
          component={PersonalMessages}
        />
        <Route path="/services/webcare" component={Webcare} />
        <Route path="/services/smspin" component={SmsPin} />
        <Route path="/services/authentication" component={Authentication} />
        <Route path="/clients" component={Clients} />
        <Route path="/our-works" component={OurWorks} />
        <Route path="/developers" component={Developers} />
        <Route path="/privacy-policy" component={PrivacyPolicy} />
        <Route path="/pricelist" component={Pricelist} />
        <Route path="/welcome" component={Welcome} />
      </Switch>
      <Footer />
    </div>
  );
};

export default App;
