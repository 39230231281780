import React, { ReactElement } from 'react';
import './Gateway.scss';
import SubPageHero from '../../../components/SubPageHero/SubPageHero';
import HeroImage from '../../../assets/images/hero/pipes.jpg';
import SecondaryNav from '../../../components/SecondaryNav/SecondaryNav';
import { useTranslation } from 'react-i18next';
import { sentenceCase, upperCase } from 'converting-case';
import Doc from '../../../assets/files/SMPP_gatway_documentation.pdf';

const Gateway: React.FC = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <div className="Gateway">
      <SecondaryNav />
      <SubPageHero image={HeroImage}>
        <h2>{sentenceCase(t('Gateway.header'))}</h2>
        <p className="smaller-padding">{t('Gateway.description part 1')}</p>
        <p>{t('Gateway.description part 2')}</p>
        <a href="mailto:support@messenger.ee" className="button is-outlined">
          {upperCase(t('contact us'))}
        </a>
        <a
          href={Doc}
          target="_blank"
          className="button is-outlined"
          rel="noreferrer"
        >
          {upperCase(t('documentation'))}
        </a>
      </SubPageHero>
    </div>
  );
};

export default Gateway;
