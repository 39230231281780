import React, { ReactElement } from 'react';
import './Messaging.scss';
import SubPageHero from '../../../components/SubPageHero/SubPageHero';
import HeroImage from '../../../assets/images/hero/phone_pattern.jpg';
import SecondaryNav from '../../../components/SecondaryNav/SecondaryNav';
import { useTranslation } from 'react-i18next';
import { sentenceCase, upperCase } from 'converting-case';
import axios from 'axios';
import { useEffect } from 'react';
import { useState } from 'react';
import PriceList from '../../../components/PriceList/PriceList';
import e from 'express';

interface PriceObject {
  country: string;
  price: number;
  price_vat: number;
}

const Messaging: React.FC = (): ReactElement => {
  const { t } = useTranslation();

  const [prices, setPrices] = useState<PriceObject[]>();

  const [currentPrice, setCurrentPrice] = useState<string>();

  const [currentVatPrice, setCurrentVatPrice] = useState<PriceObject>();

  const [displayPriceList, toggleDisplayPriceList] = useState<boolean>(false);

  const getPricelist = async () => {
    axios
      .get('/api/pricelist')
      .then((response) => {
        setPrices(response.data.priceList);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getPrice = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setCurrentPrice(e.target.value);
  };  

  const getVatPrice = (e: React.ChangeEvent<HTMLSelectElement>) => {
    // i just wanted to use prices[e.target.value] but typescript will yell at me if i try to do it :(
    let selectedCountry = prices?.find((obj) => obj.country == e.target.value);
    setCurrentVatPrice(selectedCountry);
  };

  useEffect(() => {
    getPricelist();
  }, []);

  return (
    <div className="Messaging">
      <SecondaryNav />
      <SubPageHero image={HeroImage}>
        <h2>{t('Messaging.header')}</h2>
        <p className="smaller-padding">{t('Messaging.description part 1')}</p>
        <p className="smaller-padding">{t('Messaging.description part 2')}</p>
        <p className="smaller-padding">{t('Messaging.description part 3')}</p>
        <ul>
          <li>{t('Messaging.description list.1')}</li>
          <li>{t('Messaging.description list.2')}</li>
          <li>{t('Messaging.description list.3')}</li>
          <li>{t('Messaging.description list.4')}</li>
          <li>{t('Messaging.description list.5')}</li>
          <li>{t('Messaging.description list.6')}</li>
          <li>{t('Messaging.description list.7')}</li>
        </ul>
      </SubPageHero>
      <div id="account-comparison" className="container">
        <div className="columns">
          <div className="column card">
            <div className="card-header">
              <p className="card-header-title">
                {upperCase(t('smart account'))}
              </p>
            </div>
            <div className="card-content">
              <div className="content">
                <ul>
                  <li>{t('Messaging.Create an account')}</li>
                  <li>{t('Messaging.Test for free')}</li>
                  <li>{t('Messaging.Add credit')}</li>
                  <li>{t('Messaging.Send right away')}</li>
                  <li>{t('Messaging.API')}</li>
                  <li>{t('Messaging.Personal messages')}</li>
                  {/* <li className="empty-list-item"></li> */}
                </ul>
              </div>
            </div>
            <footer className="card-footer">
              <a
                className="button is-primary"
                href="https://smart.messenger.ee/register"
                target="_blank"
                rel="noreferrer"
              >
                {upperCase(t('get started'))}
              </a>
            </footer>
          </div>
          <div className="column card">
            <div className="card-header">
              <p className="card-header-title">
                {upperCase(t('premium account'))}
              </p>
            </div>
            <div className="card-content">
              <div className="content">
                <ul>
                  {/* <li>{t('Messaging.header')}</li> */}
                  <li>{t('Messaging.Contact us')}</li>
                  <li>{t('Messaging.Sign a contract')}</li>
                  <li>{t('Messaging.We create an account for you')}</li>
                  <li>{t('Messaging.Pay monthly')}</li>
                  <li>{t('Messaging.API')}</li>
                  <li>{t('Messaging.Personal messages')}</li>
                </ul>
              </div>
            </div>
            <footer className="card-footer">
              <a
                className="button is-primary"
                href="mailto:support@messenger.ee"
              >
                {upperCase(t('contact us'))}
              </a>
            </footer>
          </div>
          <div className="column card">
            <div className="card-header">
              <p className="card-header-title">{upperCase(t('pricelist'))}</p>
            </div>
            <div className="card-content">
              <div className="content pricelist-card-content">
                <p>
                  {t('Messaging.pricelist description')}{' '}
                  <button
                    type="button"
                    className="button-as-link"
                    onClick={() => {
                      toggleDisplayPriceList(!displayPriceList);
                    }}
                  >
                    {t('Messaging.pricelist link')}
                  </button>
                </p>
                {/*<span>{t('Messaging.Prices without VAT')}</span>*/}
                <div className="price-form-container">
                  <div className="field price-dropdown">
                    <div className="control">
                      <div className="select">
                        <select onChange={getVatPrice}>
                          <option>
                            {sentenceCase(t('Messaging.Country'))}
                          </option>
                          {prices?.map((item, index) => {
                            return (
                              <option key={index} value={item.country}>
                                {item.country}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                  <table className="table is-striped is-fullwidth is-hoverable">
                    <tbody>
                      <tr>
                        <td>{sentenceCase(t('price'))} (EUR)</td>
                        <td>{t('price_vat')} (EUR)</td>
                      </tr>
                      <tr>
                        <td>{currentVatPrice?.price}</td>
                        <td>{currentVatPrice?.price_vat}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="notice-banner">
        <div className="container">
          <span>{t('Messaging.notice')}</span>
          <a className="button is-outlined" href="mailto:support@messenger.ee">
            {upperCase(t('contact us'))}
          </a>
        </div>
      </div>
      <div id="features-table">
        <div className="container">
          <div className="table-container">
            <span className="table-tab">{sentenceCase(t('features'))}:</span>
            <table className="table is-striped is-fullwidth is-hoverable">
              <tbody>
                <tr>
                  <td>{t('Messaging.table.24/7 access')}</td>
                  <td>{t('Messaging.table.No subscription or monthly fee')}</td>
                </tr>
                <tr>
                  <td>{t('Messaging.table.pay ONLY for messages sent')}</td>
                  <td>{t('Messaging.table.convenient and fast')}</td>
                </tr>
                <tr>
                  <td>{t('Messaging.table.sceduling option')}</td>
                  <td>{t('Messaging.table.statistics')}</td>
                </tr>
                <tr>
                  <td>{t('Messaging.table.message templates')}</td>
                  <td>{t('Messaging.table.platform in Estonian, English')}</td>
                </tr>
                <tr>
                  <td>{t('Messaging.table.API integration')}</td>
                  <td>
                    {t('Messaging.table.we can send your messages out for you')}
                  </td>
                </tr>
                <tr>
                  <td>{t('Messaging.table.fast support')}</td>
                  <td>{t('Messaging.table.contact groups')}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <PriceList active={displayPriceList} toggle={toggleDisplayPriceList} />
    </div>
  );
};

export default Messaging;
